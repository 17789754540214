import {
	BurstModeSharp as BurstModeSharpIcon,
	Logout as LogoutIcon,
	TollTwoTone as PaymentIcon,
	AutoStoriesRounded as GuideIcon,
} from "@mui/icons-material";
import {
	Avatar,
	Box,
	Divider,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Tooltip,
} from "@mui/material";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { MouseEvent, useContext, useState } from "react";
import {
	useAuthUser,
	useIsAuthenticated,
	useSignIn,
	useSignOut,
} from "react-auth-kit";
import { useNavigate } from "react-router-dom";

import { ProjectContext } from "#components/Contexts";
import Project from "#libs/Project";
import { EDIT_MAP_PAGE, HELP_PAGE, PAYMENT_PAGE, SHADINGS_PAGE, USER_PROFILE_PAGE } from "App";

import "#styles/components/ProjectBar";
import "#styles/glass";
import { useShadingStore } from "#components/store";


/**
 * Account menu
 */
export default function AccountMenu() {
	const shadingStore = useShadingStore.getState();

	// Menu anchor elements
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	//Callback function from logging out
	const navigate = useNavigate();
	const signIn = useSignIn();
	const signOut = useSignOut();
	const auth = useAuthUser();
	const authData = auth();
	const isAuthenticated = useIsAuthenticated();

	// Callback function from logging in
	function handleCallbackResponse(response: any) {
		const decoded = jwt_decode<JwtPayload>(response.credential); // Decode the values from the jwt response

		if (
			"email" in decoded &&
			"name" in decoded &&
			"sub" in decoded &&
			decoded.sub) {

			signIn({
				token: response.credential,  // Sign in token, not user tokens
				expiresIn: 1440,
				tokenType: "Bearer",
				authState: {
					authKey: decoded.sub,
					email: decoded.email,
					name: decoded.name,
				},
			})
			navigate(EDIT_MAP_PAGE);
		} else {
			//Throw error
			console.error("Error: not all user details are present")
		}
	}
	function handleSignOut() {
		signOut();
		googleLogout();
		shadingStore.clearShading();
		handleClose();
	}

	// Navigation button callback functions
	const handleCredits = () => navigate(PAYMENT_PAGE);
	const handleProjects = () => navigate(SHADINGS_PAGE);
	const handleProfile = () => navigate(USER_PROFILE_PAGE);
	const handleGuide = () => window.open(HELP_PAGE, "_blank", "rel=noopener noreferrer")

	const AccountMenu = () => {
		return (
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				sx={{
				}}
				slotProps={{
					paper: {
						elevation: 0,
						sx: {
							overflow: "visible",
							filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
							mt: 1.5,
							"& .MuiAvatar-root": {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							"&:before": {
								content: '""',
								display: "block",
								position: "absolute",
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: "background.paper",
								transform: "translateY(-50%) rotate(45deg)",
								zIndex: 0,
							},
						},
					},
				}}
			>
				{!isAuthenticated() ? (
					<MenuItem onClick={handleClose}>
						<GoogleLogin
							onSuccess={handleCallbackResponse}
							onError={() => { console.log("Login Failed"); }}
							useOneTap
							theme="filled_blue"
							shape="square"
						/>
					</MenuItem>
				) : (
					[
						<MenuItem key="account" onClick={handleProfile}>
							<Avatar />
							Account
						</MenuItem>,
						<Divider key="divider" />,
						<MenuItem key="shadings" onClick={handleProjects}>
							<ListItemIcon>
								<BurstModeSharpIcon fontSize="small" />
							</ListItemIcon>
							Shadings
						</MenuItem>,
						<MenuItem key="credits" onClick={handleCredits}>
							<ListItemIcon>
								<PaymentIcon fontSize="small" />
							</ListItemIcon>
							Credits
						</MenuItem>,
						<MenuItem key="credits" onClick={handleGuide}>
							<ListItemIcon>
								<GuideIcon fontSize="small" />
							</ListItemIcon>
							User Guide
						</MenuItem>,
						<MenuItem key="signout" onClick={handleSignOut}>
							<ListItemIcon>
								<LogoutIcon fontSize="small" />
							</ListItemIcon>
							Sign Out
						</MenuItem>,
					]
				)}
			</Menu>
		);
	};

	return <>
		<Box
			onClick={handleClick}
			className="clickable"
			display="flex"
			alignItems="center"
			textAlign="center"
		>
			<Tooltip title="Account">
				<IconButton
					onClick={handleClick}
					size="small"
					sx={{
						width: "46px",
						height: "46px",
						borderRadius: 10,
					}}
					aria-controls={open ? "account-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
				>
					<Avatar sx={{ width: 32, height: 32 }}>{authData?.name[0]}</Avatar>
				</IconButton>
			</Tooltip>
		</Box>
		<AccountMenu />
	</>;
}
