import L from "leaflet";
// @ts-ignore
import SelectArea from "leaflet-area-select";  // This is required to import AreaSelect code
import { Dispatch } from "react";
import { Rectangle, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";


export default function AreaSelect({ selection, setSelection }: {
	selection: L.LatLngBounds;
	setSelection: Dispatch<L.LatLngBounds>;
}) {
	const map = useMap();
	if (!map.selectArea) return null;

	map.selectArea.enable();
	map.selectArea.setAutoDisable(true);
	map.on("selectarea:selected", (e: L.LeafletEvent) => {
		const event = e as SelectArea.SelectAreaSelected;
		setSelection(event.bounds);
	});

	// TODO: Invert rectangle area selection (using leaflet.snogylop)
	// TODO: Look at renderer padding (to keep loaded offscreen)
	return (
		<Rectangle
			bounds={selection}
			weight={1}
			color="black"
			pathOptions={{ interactive: true }}
		/>
	);
}
