// Written by: FIT3162 CS Team 1
// Last modified: 19/09/23
// Title: User profile page element

import { CreditsContext } from "#components/Contexts";
import { useShadingStore } from "#components/store";
import {
	deleteAccount,
	getUserApiKey,
	getUserProjectAmount,
	setUserApiKey
} from "#libs/apis/backend";
import { User } from "#libs/user";
import PageLayout from "#pages/PageLayout";
import "#styles/pages/UserProfile";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
	Alert,
	Box,
	Button,
	Collapse,
	Container,
	IconButton,
	TextField
} from "@mui/material";
import { googleLogout } from '@react-oauth/google';
import { EDIT_MAP_PAGE, PAYMENT_PAGE, SHADINGS_PAGE } from "App";
import React, { ChangeEvent, useContext, useState } from "react";
import {
	useAuthUser, useSignOut,
} from "react-auth-kit";
import { useNavigate } from 'react-router-dom';


/**
 * Displays the details about the user's account:
 * @param email Email address of Eduard cloud account
 */
function AccountDetails({ user }: { user: User | null }) {
  const name = user?.name;
  const email = user?.email;

  return (
    <div>
      <b>{name}</b>
      <p>{email}</p>
    </div>
  );
}


/**
 * Update API key details
 * @param User User object containing the user's details
 */
function ApiKeyDetails({ user }: { user: User | null }) {
  // States
  const [apiKey, setApiKey] = useState<string>("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);

  // Functions for updating the API key in the database
  const handleApiKeyChange = (e: ChangeEvent<HTMLInputElement>) => setApiKey(e.target.value);

  function handleSetApiKey() {
    const setApiKey = async () => {
      if (user == null)
        throw new Error("User is not authenticated");

      const success = await setUserApiKey(user, apiKey);
      (success) ? setOpenSuccess(true) : setOpenFailed(true);
    };

    setApiKey().catch(console.error);
  }

  // Get API key details from database
  React.useEffect(() => {
    // Retrieve API key from database
    const updatePageApiKey = async () => {
      if (user == null)
        throw new Error("User is not authenticated");

      const userApiKey = await getUserApiKey(user);
      setApiKey(userApiKey);
    };

    updatePageApiKey().catch(console.error);
  }, [user]);

  // Close action for update dialogs
  const ActionButton = (onClickHandler: () => void) => (
    <IconButton aria-label="close" color="inherit" size="small" onClick={onClickHandler}>
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );

  return <>
    <h3 className="custom-h3">OpenTopography API Key</h3>
    <p>
      A free API key is required to download elevation models from OpenTopography.
    </p>
    <p>
      Create an account and request an API key at <a href="https://OpenTopography.org" target="_blank">OpenTopography.org</a>.
    </p>
    <TextField
      id="outlined-required"
      fullWidth
      label="OpenTopography API Key"
      value={apiKey}
      onChange={handleApiKeyChange}
      onBlur={handleSetApiKey}
    />
    <Box width="100%">
      <Collapse in={openSuccess}>
        <Alert
          severity="success"
          children="API key updated successfully"
          action={ActionButton(() => setOpenSuccess(false))}
          sx={{ mb: 2 }}
        />
      </Collapse>
      <Collapse in={openFailed}>
        <Alert
          severity="error"
          children="Error: API key failed to update"
          action={ActionButton(() => setOpenFailed(false))}
          sx={{ mb: 2 }}
        />
      </Collapse>
    </Box>
    <br />
  </>;
}


/**
 * Displays current user credits count.
 * @param userCredits The number of Eduard Credits the user account has.
 */
function CreditsDetails({ userCredits }: { userCredits: number }) {
  const navigate = useNavigate();

  return <>
    <h3 className="custom-h3">Eduard Credits</h3>
    <p>Current number of Eduard Credits: {userCredits}</p>
    <button
      type="button"
      onClick={() => navigate(PAYMENT_PAGE)}
      className="details-container__button navigate" >
      Purchase Credits
    </button>
  </>;
}


/**
 * Displays the current number of active shadings for the user
 * @param user User object containing the user's details.
 */
function ShadingsDetails({ user }: { user: User | null }) {
  const navigate = useNavigate();
  const [userProjects, setUserProjects] = useState(0);

  const shadingsText = (userProjects <= 0) ? "You have no shading yet."
    : (userProjects === 1) ? "You have one saved shading."
      : `You have ${userProjects} saved shadings.`;

  React.useEffect(() => {
    // Retrieve number of projects from database
    const updateProjectNumber = async () => {
      if (user == null)
        throw new Error("User is not authenticated");

      const projectAmount = await getUserProjectAmount(user);
      setUserProjects(projectAmount);
    };

    updateProjectNumber().catch(console.error);
  }, [user]);


  return <>
    <h3 className="custom-h3">Shadings</h3>
    <p>{shadingsText}</p>
    <button
      type="button"
      onClick={() => navigate(SHADINGS_PAGE)}
      className="details-container__button navigate" >
      My Shadings
    </button>
  </>;
}


/**
 * Delete account button + confirmation modals
 * @param user User object containing the user's details.
 * @param userCredits The number of Eduard Credits the user account has.
 */
function DeleteAccount({ user, userCredits }: {
  user: User | null,
  userCredits: number,
}) {
  // Modal states
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [finalWarningModal, setFinalWarningModal] = useState(false)

  // Projects context
  const { clearShading } = useShadingStore();

  // Authentication handlers
  const signOut = useSignOut();

  // Functions to handle the dialogue boxes
  const handleOpenConfirmation = () => setShowConfirmationModal(true);
  const handleCloseConfirmation = () => setShowConfirmationModal(false);
  const handleOpenFinalWarning = () => setFinalWarningModal(true);
  const handleCloseFinalWarning = () => setFinalWarningModal(false);

  // Signs user out of account
  function handleSignOut() {
    signOut();
    googleLogout();
  }

  // Functions to delete the user's account from the database
  const handleDeleteAccount = () => {
    handleCloseConfirmation();
    if (userCredits > 0) {
      handleOpenFinalWarning();
    } else {
      handleFinalDeleteAccount();
    }
  };

  const handleFinalDeleteAccount = () => {
    const deleteUser = async () => {
      if (user == null)
        throw new Error("User is not authenticated");

      const success = await deleteAccount(user);
      if (success) {
        clearShading();
        setFinalWarningModal(false);
        handleSignOut();
      } else {
        console.log("Error: account deletion failed");
      }
    };

    deleteUser().catch(console.error);
  };

  const initialWarningText = (userCredits > 0)
    ? "Deleting your account will result in the loss of all your shadings and your purchased credits."
    : "Deleting your account will result in the loss of all your shadings.";

  return <>
    <button type="button" className="details-container__button delete" onClick={handleOpenConfirmation}>Delete Account</button>
    {/* Delete Account warnings */}
    {showConfirmationModal && (
      <div className="modal__overlay">
        <div className="modal__popup">
          <p><b>Warning: This cannot be undone.</b></p>
          <p>{initialWarningText}</p>
          <div>
            <button className="modal__button neutral" onClick={handleCloseConfirmation}>Cancel</button>
            <button className="modal__button no" onClick={handleDeleteAccount}>Delete Account</button>
          </div>
        </div>
      </div>
    )}
    {finalWarningModal && (
      <div className="modal__overlay">
        <div className="modal__popup">
          <p><b>Confirmation: This cannot be undone.</b></p>
          <p>Are you sure you want to delete your account? You will lose {userCredits} credits.</p>
          <div>
            <button className="modal__button neutral" onClick={handleCloseFinalWarning}>Cancel</button>
            <button className="modal__button no" onClick={handleFinalDeleteAccount}>Delete</button>
          </div>
        </div>
      </div>
    )}
  </>;
}


/**
 * Displays the Users profile email, API keys, credits, shadings, and delete account
 * @returns React element
 */
function UserProfile(): JSX.Element {
  // Credits context
  const { userCredits } = useContext(CreditsContext);

  // Auth details
  const auth = useAuthUser();
  const user = auth() as User | null;

  // Navigation helper functions
  const navigate = useNavigate();

  return (
    <Container className="details-container__root">
      <Button
        children="Back"
        onClick={() => navigate(EDIT_MAP_PAGE)}
        startIcon={<KeyboardBackspaceIcon />} />
      <div style={{ maxWidth: "750px" }}>
        <span className="details-container__header">
          <h1>Account Details</h1>
        </span>
        <div className="details-container__content">
          <AccountDetails user={user} />
          <hr />
          <ApiKeyDetails user={user} />
          <hr />
          <CreditsDetails userCredits={userCredits} />
          <hr />
          <ShadingsDetails user={user} />
          <hr />
          <DeleteAccount user={user} userCredits={userCredits} />
          <hr />
        </div>
      </div>
    </Container>
  );
}


function UserProfilePage(): JSX.Element {
  return (
    <PageLayout>
			<UserProfile />
    </PageLayout>
  );
}

export default UserProfilePage;
