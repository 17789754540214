import { CreditsContext } from "#components/Contexts";
import FadeInSection from "#components/FadeSection";
import CreateShadingModal from "#components/modals/CreateShadingModal";
import { InsufficientFundsModal } from "#components/modals/modals";
import { ProgressSpinner } from "#components/ProgressStatus";
import { useShadingStore } from "#components/store";
import { deleteMap, getMapData, getUserMaps, setShadingNameInDB } from "#libs/apis/backend";
import { ProcessingSettings } from "#libs/types";
import { User } from "#libs/user";
import PageLayout from "#pages/PageLayout";
import "#styles/pages/ProjectsPage";
import {
	DeleteOutlined as DeleteOutlinedIcon,
	KeyboardBackspace as KeyboardBackspaceIcon,
	AddPhotoAlternateOutlined as NewShadingIcon
} from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	TextField
} from "@mui/material";
import { EDIT_MAP_PAGE } from "App";
import L from "leaflet";
import React, { useContext, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const MAX_SHADINGS = 5;

interface ShadingRequestProps {
	id: string,
	name: string,
	previewImage: string,
	apiKey: string,
}

/**
 * Projects page
 * @returns  React element
 */
function ShadingsPage(): JSX.Element {
	const [shadings, setUserShadings] = useState<ShadingRequestProps[]>([]);
	const [hasFetched, setFetched] = useState<boolean>(false);

	const [openServerModal, setOpenServerModal] = useState<boolean>(false);
	const [openCreateShadingModal, setCreateShadingModal] = useState<boolean>(false);
	const [openNoCreditsModal, setNoCreditsModal] = useState<boolean>(false);

	// Navigation helpers
	const navigate = useNavigate();
	const navigateToEditPage = () => navigate(EDIT_MAP_PAGE);

	// Get user auth key
	const auth = useAuthUser();
	const user = auth() as User | null;

	const { userCredits, updatePageCredits } = useContext(CreditsContext);

	// Async function to retrieve a list of the user's projects from the database
	const fetchProjects = async () => {
		if (user == null)
			throw new Error("User is not authenticated");

		const userProjects = await getUserMaps(user);
		if (userProjects.length) {
			const projectData = userProjects.map((project: any) => {
				return {
					id: project.mapId,
					name: project.mapName,
					previewImage: `data:image/png;base64,${project.mapThumbnail}`,
					imageHash: Date.now(),
				};
			});
			setUserShadings(projectData);
			setFetched(true);
		}
	}

	// Creades delete function for target shading
	const createDeleteHandler = (shadingId: string) => {
		return async () => {
			if (user == null)
				throw new Error("User is not authenticated");

			const isDeleteSuccessful = await deleteMap(shadingId, user);
			if (isDeleteSuccessful) {
				// Remove deleted tile from view.
				setUserShadings(
					userShadings => userShadings.filter(shading => shading.id !== shadingId)
				);
			}
			else {
				setOpenServerModal(true);
			}
		}
	}

	// Check if user has sufficient credits
	const onNewShadingClick = async () => {
		await updatePageCredits();
		if (userCredits > 0) {
			setCreateShadingModal(true)
		}
		else {
			setNoCreditsModal(true);
		}
	};

	// Creates new project with input name
	const handleCreateShading = () => {
		navigate(EDIT_MAP_PAGE);
	};

	// If no projects are present on page load, retrieve the projects
	if (!hasFetched) fetchProjects();

	// Create a list of cards from the project list
	const cards = shadings.map((shading: ShadingRequestProps) =>
		<ProjectCard
			shadingId={shading.id}
			shadingName={shading.name}
			previewImage={shading.previewImage}
			deleteCallback={createDeleteHandler(shading.id)}
			user={user}
		/>
	);

	return <>
		<PageLayout>
			<div className="projects-page__layout">
				<section className="projects-page__content">
					<Button
						children="Back"
						onClick={navigateToEditPage}
						startIcon={<KeyboardBackspaceIcon />}
						sx={{ 'justify-content': "left", maxWidth: "80px" }}
					/>
					<section className="projects__header">
						<h4>Shadings</h4>
						<h4>Total: {shadings.length}</h4>
					</section>
					<Button
						children="New shading"
						onClick={onNewShadingClick}
						startIcon={<NewShadingIcon />}
						variant="outlined"
						disableFocusRipple
						sx={{
							justifyContent: "left",
							width: "200px",
							minHeight: "50px",
							margin: '5px',
							marginInline: 0,
							color: "var(--primary-text)",
							background: "var(--bg-color)",
							zIndex: 10,
						}}
					/>
					<div className="projects__shadow--top" />
					<div className="projects__content">{cards}</div>
					<div className="projects__shadow--bottom" />
				</section>
			</div>
		</PageLayout>
		<DeleteFailedDialog open={openServerModal} setOpen={setOpenServerModal} />
		<CreateShadingModal
			open={openCreateShadingModal}
			onConfirm={handleCreateShading}
			onCancel={() => setCreateShadingModal(false)}
		/>
		<InsufficientFundsModal isOpen={openNoCreditsModal} onCancel={() => setNoCreditsModal(false)} />
	</>
}

/**
 * Cards row to display group of shading cards
 * @param props List of Cards elements
 */
function CardRow(props: { children: React.ReactNode }) {
	return (
		<FadeInSection>
			<section className="projects__content-row">
				{props.children}
			</section>
		</FadeInSection>
	)
}

/**
 * Base card used for projects
 */
function ProjectCardBase(props: { children: React.ReactNode }) {
	// NOTE: focusRipple prop disables the pulsating circle effect from MUI
	return (
		<div className="project-card__base" >
			{props.children}
		</div>
	)
}

/**
 * Card for displaying and selecting an existing project
 * @param shadingId		Map Id of the shading.
 * @param name 				Name of the shading, can be renamed.
 *
 */
function ProjectCard({ shadingId, shadingName: initialShadingName, previewImage, deleteCallback, user }: {
	shadingId: string,
	shadingName: string,
	previewImage: string,
	deleteCallback: () => void,
	user: User | null,
}) {
	const { loadShading } = useShadingStore();

	const [shadingName, setShadingName] = useState<string>(initialShadingName);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
	const [isDeleting, setDeleting] = useState<boolean>(false);

	const navigate = useNavigate();

	// Load project into context and return to Editing page
	const handleSelected = async () => {
		if (user == null)
			throw new Error("User is not authenticated");

		const res = await getMapData(shadingId, user);  // FIXME: Why didnt I use an actual type here>?!!?

		const bounds = new L.LatLngBounds([res.mapSouth, res.mapWest], [res.mapNorth, res.mapEast]);
		const settings: ProcessingSettings = {
			modelNo: res.mapModelNo,
			lightRotation: res.mapAngle,
			generalization: res.mapMacro,
			generalizationDetails: res.mapMicro,
			aerialPerspective: res.mapAP,
			slopeDarkness: res.mapContrast,
			elevationRangeMin: res.mapTMin,
			elevationRangeMax: res.mapTMax,
			flatAreaAmount: res.mapFlatAmount,
			flatAreaSize: res.mapFlatSize,
		}
		loadShading(shadingId, res.projectName, bounds, settings);

		navigate(EDIT_MAP_PAGE);
	}

	// Delete project from the database
	const deleteShading = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setOpenDeleteDialog(true);
	}

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setShadingName(e.target.value);
	}

	/**
	 * Handle project name updates, reverts to Untitled map if empty
	 * @param aShadingName given name for the shading
	 */
	const handleNameUpdate = async (aShadingName: string) => {
		if (user == null)
			throw new Error("User is not authenticated");

		// Get the text from the input box, check length is not 0
		const newShadingName = (aShadingName.length > 0) ? aShadingName : "Untitled map";
		setShadingName(newShadingName);

		const success = await setShadingNameInDB(shadingId, newShadingName, user);
		if (!success) {
			// FIXME: have a propper error for this
			console.error("Name update failed");
		}
	};

	// Handles name update on blur of input element
	const handleBlurNameUpdate = (e: React.FocusEvent<HTMLInputElement>) => {
		handleNameUpdate(e.target.value);
	}

	// Check for enter keypress to handle name change
	const handleEnterNameUpdate = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			const inputElement = e.target as HTMLInputElement;
			handleNameUpdate(inputElement.value);
			inputElement.blur();
		}
	}

	// Display deleting overlay on card
	const handleDelete = async () => {
		setDeleting(true);
		await deleteCallback();
		setDeleting(false);
	}

	return (
		<>
			<ProjectCardBase >
				<Button
					className="project-card__image-container"
					onClick={handleSelected}
					disabled={isDeleting}
					focusRipple={false} >
					<img key={shadingId} src={`${previewImage}`} alt={shadingName} />
					<div className="project-card__delete-overlay" >
						{isDeleting && <ProgressSpinner text="deleting" />}
					</div>
				</Button>
				<div style={{ display: 'grid', gridTemplateColumns: "40px 1fr 40px", width: '100%', paddingTop: '5px' }}>
					<TextField
						key={shadingId}
						value={shadingName}
						defaultValue={shadingName}
						onChange={handleChangeName}
						onKeyDown={handleEnterNameUpdate}
						onBlur={handleBlurNameUpdate}
						required
						variant="standard"
						className="project-card__input-label"
						sx={{ gridColumn: "2" }}
						inputProps={{ style: { textAlign: 'center', color: 'var(--primary-text)' } }}
					/>
					<IconButton onClick={deleteShading} style={{ gridColumn: "3", color: "var(--primary-text)" }}>
						<DeleteOutlinedIcon />
					</IconButton>
				</div>
			</ProjectCardBase>
			<DeleteShadingDialog open={openDeleteDialog} setOpen={setOpenDeleteDialog} shadingName={shadingName} handleDelete={handleDelete} />
		</>
	);
}

/***
 * Dialog box for requesting OpenTopography API Key.
 */
function DeleteShadingDialog({ open: isOpen, setOpen, shadingName, handleDelete: onDelete }: {
	open: boolean,
	setOpen: React.Dispatch<boolean>,
	shadingName: string,
	handleDelete: () => void,
}) {
	// Close the dialogue box
	const handleClose = () => setOpen(false);

	// Delete project from database
	const handleDelete = () => {
		onDelete()
		handleClose();
	};

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
      <DialogTitle>Delete Shading</DialogTitle>
      <DialogContent>
        <div>
          <p>{"Are you sure you want to permanently delete the shading "}<b><i>{shadingName}</i></b>?<br/>You cannot undo this action.</p>
        </div>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" className="neutral-button">Cancel</Button>
        <Button onClick={handleDelete} variant="text" className="no-button">Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

/***
 * Dialog box for failing to delete shading from the server.
 */
function DeleteFailedDialog({ open: isOpen, setOpen }: {
	open: boolean,
	setOpen: React.Dispatch<boolean>,
}) {
	// Close the dialogue box
	const handleClose = () => setOpen(false);

  return (
    <Dialog open={isOpen}  fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
      <DialogTitle>Shading could not be deleted.</DialogTitle>
      <DialogContent>
        <div>
			Unable to delete the shading from the server. Please try again later.
        </div>
        {/* <DialogContentText>Unable to delete shading from the server, please try again later.</DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="neutral-button">Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShadingsPage;
