// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Custom type definitions

import { ElevationModel, Projection } from "#libs/Project";

export type ProcessingSettings = {
  modelNo: number;
  lightRotation: number;
  generalization: number;
  generalizationDetails: number;
  aerialPerspective: number;
  slopeDarkness: number;
  elevationRangeMin: number;
  elevationRangeMax: number;
  flatAreaAmount: number;
  flatAreaSize: number;
};

export type Bounds = {
  north: number;
  south: number;
  east: number;
  west: number;
}

export interface OpenTopographyElevationData {
	bounds: L.LatLngBounds;
	elevationModel: ElevationModel;
	projection: Projection;
}

export type ElevationData = OpenTopographyElevationData;


export enum ImageFormat {
	GEOTIFF = "GeoTIFF",
	JPEG = "JPEG",
	PNG = "PNG",
}

export class Render
{
	readonly format: ImageFormat;
	readonly src: string;
  readonly hash: number;
	readonly world: string | null;
	readonly projection: string | null;

  constructor(imageSrc: string, format: ImageFormat, world?: string, projection?: string) {
    this.src = imageSrc;
    this.format = format;
    this.hash = Date.now();
    this.world = world || null;
    this.projection = projection || null;
  }
};

// FIXME: Deprecated interface, we should instead move to using the 'Render' class
export interface ShadingRender {
  hash: number;
  src: string;
	width: number;
	height: number;
}

export type CreditsRatio = {
  credits: number;
  cost: number;
  currency: string;
};

export interface Props {
  children?: React.ReactNode;
}
