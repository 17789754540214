// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Render image display element

import EduardLogo from "#assets/logo.png";
import { ShadingRender } from "#libs/types";
import { INFO_PAGE, PRIVACY_POLICY_PAGE } from "App";
import L from "leaflet";
import { ImageOverlay, MapContainer } from "react-leaflet";

import "#styles/components/MapDisplay";
import "leaflet/dist/leaflet.css";

/**
 * Map display providing either a dropzone or a zoomable image.
 * @param renderUrl url of image to display
 */
function MapDisplay({ shading }: { shading: ShadingRender }) {
	const PADDING = 0.5;

	// Image element use to determine dims of shading
	const aspectRatio = shading.width / shading.height || 1;
	const bounds = new L.LatLngBounds([[-90, -180 * aspectRatio], [90, 180 * aspectRatio]])

	// FIXME: Leaflet map tries to rerender without memo, still also issue with rerenders with settings update
	return (
		<MapContainer
			id="map"
			className="image-zoom-container"
			center={[0, 0]}
			zoom={1}
			minZoom={0}
			maxZoom={3}
			maxBounds={bounds.pad(PADDING)}
			maxBoundsViscosity={0.5}
			attributionControl={false}>
			<ImageOverlay
				key={shading.hash}
				url={shading.src}
				bounds={bounds}
				errorOverlayUrl={EduardLogo}
				attribution={`&copy; 2024 Monash University | <a href=${PRIVACY_POLICY_PAGE}>Privacy Policy</a> | <a href=${INFO_PAGE}>Contact</a>`}
			/>
		</MapContainer>
	);
}

export default MapDisplay;
