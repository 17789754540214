import L from "leaflet";

// Define a function named degrees_to_radians that converts degrees to radians.
export function degrees_to_radians(degrees: number) : number
{
  // Multiply degrees by pi divided by 180 to convert to radians.
  return degrees * (Math.PI / 180);
}

/**
 * The area limits for the API are calculated based on spherical lat/lon coordinates
 * using the PostGIS st_Area function.
 * This function utilizes the WGS-84 spheroid in its calculations
 * https://www.johndcook.com/blog/2023/02/21/sphere-grid-area/
 */
export function PostGIS_ST_Area(bounds: L.LatLngBounds): number {
	const EARTH_RADIUS = 6_371; // km

	const lat1 = degrees_to_radians(bounds.getSouth());
	const lat2 = degrees_to_radians(bounds.getNorth());
	const lon1 = bounds.getEast();
	const lon2 = bounds.getWest();

	const area = (
		Math.PI * Math.pow(EARTH_RADIUS, 2) * Math.abs(Math.sin(lat1) - Math.sin(lat2)) * (lon1 - lon2) / 180
	);
	return area;
}
