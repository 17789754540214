import logo from "#assets/logo.png";
import { BaseModal } from "#components/modals/modals";

/**
 * OpenTopography error reponse modal
 * @param { isOpen, errorMessage, httpCode, onCancel }
 */
export function OpenTopographyErrorModal({ isOpen, errorMessage, onCancel }: {
	isOpen: boolean;
	errorMessage: string | null;
	onCancel: () => void;
}) {
	return (
		<BaseModal
			open={isOpen}
			heading="OpenTopography Error"
			noButton={{ title: "OK", onClick: onCancel }} >
			<div style={{ paddingInline: 30, display: "grid" }}>
				<img className="eduard-logo" style={{ justifySelf: "center", marginBottom: 10 }} src={logo} alt="Eduard icon" />
				<p>{errorMessage}</p>
			</div>
		</BaseModal>
	);
}
