// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Back-end request functions

import axios, { AxiosError, AxiosResponse } from "axios";
import { Stripe, loadStripe } from "@stripe/stripe-js";

import { Bounds, CreditsRatio, ImageFormat, ShadingRender, ProcessingSettings, Render, ElevationData } from "#libs/types";
import Project, { DEFAULT_PROJECTION, ElevationModel, Projection } from "#libs/Project";
import { User } from "#libs/user";
import L from "leaflet";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,  // NOTE: Combines URLS if not absolute URL: begins with "<scheme>://"" or "//" (protocol-relative url)
  headers: {                                                             // See: https://stackoverflow.com/a/66038602
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
  }
});

/**
 * Starts a render request on the back-end
 * @returns Request unique ID string
 */
async function startRequest() : Promise<string>
{
  const { data } = await api.post('/api/start_request', {});
  return data.requestId;
}

/**
 * Cancel a render request
 * @param requestId Request unique ID string
 * @returns Cancel success response string
 */
async function cancelRequest(requestId: string) : Promise<string>
{
    try {
        console.log("Trying to cancel request:" + requestId)
        const { data } = await api.post('/api/cancel_request', {
          data: {
            requestId: requestId
          }
      });
        return data;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('error message: ', error.message);
        return error.message;
      } else {
        console.error('unexpected error: ', error);
        return '';
      }
    }
}

/**
 * Get render progress from the back-end
 * @param requestId Request unique ID string
 * @returns Progress percentage integer 0-100
 */
async function getProgress(requestId: string) : Promise<number>
{
  try {
    const { data } = await api.post('/api/progress', {
      data: {
        requestId: requestId
      }
    });
    return data.progress;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return 100
    } else {
      console.error('unexpected error: ', error);
      return 100;
    }
  }
}

/**
 * Get a user's API key from database
 * @param user User to get API key for.
 * @returns User API key string
 */
async function getUserApiKey(user: User) : Promise<string>
{
  try {
    const { data } = await api.post('/api/get_api_key', {
      data: {
        authKey: user.authKey,
        email: user.email,
      }
    });
    return data.apiKey;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
    }
    return "";
  }
}

/**
 * Set a user's API key in the database
 * @param user User to set API key for.
 * @returns Success boolean
 */
async function setUserApiKey(user: User, newApiKey: string) : Promise<boolean>
{
  try {
    const { data } = await api.post('/api/set_api_key', {
      data: {
        authKey: user.authKey,
        email: user.email,
        apiKey: newApiKey,
      }
    });
    return data.success;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}

/**
 * Delete a user account in the database
 * @param user User to delete account for.
 * @returns Success boolean
 */
async function deleteAccount(user: User) : Promise<boolean>
{
  try {
    const { data } = await api.post('/api/delete_account', {
      data: {
        authKey: user.authKey,
        email: user.email,
      }
    });

    if (data.success) {
      return true;
    } else {
      return false;
    }

  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}

/**
 * Get user credit balance from database
 * @param user User to get credit balance for.
 * @returns Integer user credit balance.
 */
async function getUserCredits(user: User) : Promise<number>
{
  try {
    const { data } = await api.post('/api/get_credits', {
      data: {
        authKey: user.authKey,
        email: user.email,
      }
    });

    return data.credits;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return -1;
    } else {
      console.error('unexpected error: ', error);
      return -1;
    }
  }
}

/**
 * Get amount of projects a user has
 * @param user User to get project amount for.
 * @returns Integer number of projects
 */
async function getUserProjectAmount(user: User) : Promise<number>
{
  try {
    const { data } = await api.post('/api/get_project_amount', {
      data: {
        authKey: user.authKey,
        email: user.email,
      }
    });
    return data.numProjects;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return -1;
    } else {
      console.error('unexpected error: ', error);
      return -1;
    }
  }
}


/**
 * Get a list of user map details
 * @param user User to get maps for.
 * @returns List of all maps created by user
 */
async function getUserMaps(user: User) : Promise<any>
{
  try {
    const { data } = await api.post('/api/get_all_user_maps', {
      data: {
        authKey: user.authKey,
        email: user.email,
      }
    });
    return data;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return '';
    } else {
      console.error('unexpected error: ', error);
      return '';
    }
  }
}

/**
 * Get all data relating to a map
 * @param mapId Unique map ID string
 * @param user User to get map data for.
 * @returns Map data object
 */
async function getMapData(mapId: string, user: User) : Promise<any>
{
  try {
    const { data } = await api.post('/api/get_map_data', {
      data: {
        authKey: user.authKey,
        email: user.email,
        mapId: mapId
      }
    });

    return data

  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return '';
    } else {
      console.error('unexpected error: ', error);
      return '';
    }
  }
}

/**
 * Update a map name in the database
 * @param mapId Unique map ID string
 * @param mapName New map name string
 * @returns Success boolean
 */
async function setShadingNameInDB(mapId: string, mapName: string, user: User) : Promise<boolean>
{
  try {
    const { data } = await api.post('/api/update_map_name', {
      data: {
        authKey: user.authKey,
        email: user.email,
        mapId: mapId,
        mapName: mapName
      }
    });

    if (data.success) {
      return true;
    } else {
      return false;
    }

  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}

/**
 * Creates a map in the backend database.
 */
async function createShading(
	user: User,
	mapName: string,
	elevationData: ElevationData,
) : Promise<string> {
  const { data } = await api.post('/api/create_map', {
    data: {
      authKey: user.authKey,
      email: user.email,
      mapName: mapName,
			elevationData: {
				bounds: {
					north: elevationData.bounds.getNorth(),
					south: elevationData.bounds.getSouth(),
					east: elevationData.bounds.getEast(),
					west: elevationData.bounds.getWest(),
				},
				elevationModel: elevationData.elevationModel.demtype,
				projection: elevationData.projection,
			},
    }
  });
  return data.mapId.toString();;
}

/**
 * Delete a map in the database
 * @param mapId Unique map ID string
 * @returns Success boolean
 *
 * FIXME: Should verify the user auth key with the map ID
 */
async function deleteMap(mapId: string, user: User) : Promise<boolean>
{
  try {
    const { data } = await api.post('/api/delete_map', {
      data: {
        authKey: user.authKey,
        email: user.email,
        mapId: mapId,
      }
    });

    if (data.success) {
      return true;
    } else {
      return false;
    }

  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}

/**
 * Render a shaded relief image on the back-end server using cached data and current settings
 * @param settings Render settings object
 * @param isDemo Boolean which is true if a demo
 * @param requestId Unique render request code
 * @param projection Selected projection name
 * @param authKey User auth key string
 * @param mapId Unique map ID string
 * @returns Processed shaded relief image based on cached data and settings
 */
async function processCachedTiff(
  requestId: string,
  user: User | null,
  mapId: string,
  settings: ProcessingSettings,
) :
    Promise<ShadingRender>
{

  const authKey = user?.authKey || "demo_authkey";
  const email = user?.email || "demo_email";

  try {
		const { data, status } = await api.post('/api/process', {
				data: {
						authKey: authKey,
						email: email,
						isDemo: false,
						mapId: mapId,
						requestId: requestId,
						settings: settings,
				}
		});

		const shadingSrc = `data:image/jpg;base64,${data.jpg}`;

		const shadingImage = new Image();
		shadingImage.src = shadingSrc;
		await shadingImage.decode();

		const result: ShadingRender = {
			hash: Date.now(),
			src:  shadingSrc,
			width: shadingImage.naturalWidth,
			height: shadingImage.naturalHeight,
		}

		return result;
  }
  catch (error) {
		console.log(error);
		throw error;
  }
}

export async function renderDemoShading(
  requestId: string,
	settings: ProcessingSettings,
) :
Promise<ShadingRender>
{

	const authKey = "demo_authkey";
  const email = "demo_email";
	const mapId = "demo_id";

  try {
		// TODO: This should call a specific render demo route on the back-end
		// Currently this logic requires to be separated out
		const { data, status } = await api.post('/api/process', {
				data: {
						authKey: authKey,
						email: email,
						isDemo: true,
						mapId: mapId,
						requestId: requestId,
						settings: settings,
				}
		});

		const shadingSrc = `data:image/jpg;base64,${data.jpg}`;

		const shadingImage = new Image();
		shadingImage.src = shadingSrc;
		await shadingImage.decode();

		const result: ShadingRender = {
			hash: Date.now(),
			src:  shadingSrc,
			width: shadingImage.naturalWidth,
			height: shadingImage.naturalHeight,
		}

		return result;
  }
  catch (error) {
		console.log(error);
		throw error;
  }
}

export async function downloadShading(
  user: User,
	shadingId: string,
	downloadFormat: ImageFormat,
) : Promise<Render>
{
	const { data, status } = await api.post('/api/download_request', {
		data: {
			mapId: shadingId,
			downloadType: downloadFormat,
			authKey: user.authKey,
			email: user.email,
		}
	});

  const imageFormat = ImageFormat[data['format'].toUpperCase() as keyof typeof ImageFormat];
	const result = new Render(data['src'], imageFormat, data['world'], data['projection']);

	return result;
}

export async function downloadDemoShading(
  downloadFormat: ImageFormat,
	processingSettings: ProcessingSettings
) : Promise<Render>
{
	const { data, status } = await api.post('/api/download_demo_request', {
		data: {
			downloadType: downloadFormat,
			// FIXME: Currently we cache all demo shadings and hash the param settings.
			// We need to know the processing settings to download
			processingSettings,
		}
	});

  const imageFormat = ImageFormat[data['format'].toUpperCase() as keyof typeof ImageFormat];
	const result = new Render(data['src'], imageFormat, data['world'], data['projection']);

	return result;
}



let stripePromise: Promise<Stripe | null> | null = null;
/**
 * Load Stripe connection with key
 * @returns  Stripe promise
 */
const getStripe = () => {
  if (!stripePromise && process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

/**
 * Gets unit amount in cents
 * Update: Default currency to USD (18/9/2024)
 *
 * @param toCurrency Currency identifier string
 * @returns Number of cents
 */
function getUnitAmount(toCurrency: string): number {
  return 100;
}

/***
 * Redirect and handle stripe purchase
 */
async function handleStripePurchase(creditAmount: number, selectedOption: string, user: User) {
  const stripe = await getStripe();

  try {
    // Send the purchase checkout details to the back-end
    const response = await api.post(`/api/payment`, {
      quantity: creditAmount,
      currency: selectedOption,
      unitAmount: getUnitAmount(selectedOption),
      authKey: user.authKey,
      email: user.email,
    });

    // Display error if purchase failed
    if (response.status !== 200) {
      throw new Error('Network response was not ok ' + response.statusText);
    }

    // Redirect to the Stripe checkout
    const { id: sessionId } = await response.data;
    if (stripe) {
      const { error } = await stripe.redirectToCheckout({ sessionId });
    }
    else { console.error("error"); }
  } catch (error) {
    console.error('Error:', error);
  }
}

async function getPurchaseRatio(): Promise<CreditsRatio> {
    const response = await api.get('/api/prices');

    // Display error if purchase failed
    if (response.status !== 200) {
      throw new Error('Unable to fetch prices from server ' + response.statusText);
    }
    return {
      credits: response.data['credits'],
      cost: response.data['cost'],
      currency: response.data['currency']
    };
}

async function awakenModal(): Promise<void> {
  await api.post('/api/wakeup_server')
    .then((repsonse: AxiosResponse) => console.log("Awoken Modal:", repsonse.data["status"]))
    .catch((error: AxiosError) => console.error("Failed to wake modal service: ", error));
}


// function post_project(geoTiff: any, settings: Processing) {}
export {
  startRequest,
  cancelRequest,
  processCachedTiff,
  getProgress,
  getUserApiKey,
  setUserApiKey,
  getUserCredits,
  deleteAccount,
  getUserMaps,
  getMapData,
  deleteMap,
  getUserProjectAmount,
  setShadingNameInDB,
  handleStripePurchase,
  getPurchaseRatio,
  createShading,
  awakenModal
};
