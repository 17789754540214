import {
	FilterCenterFocus as FilterCenterFocusIcon,
	HighlightAlt as HighlightAltIcon,
	ZoomInMap as ZoomInMapIcon
} from "@mui/icons-material";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";

/**
 * FIXME: Currently this toggle will trigger a area select action rather than a zoom.
 */
export function AreaZoomToggle() {
	const [shiftActive, setShiftActive] = useState(false);
	const map = useMap();

	map.on("selectarea:selected", () => {
		setShiftActive((prevState) => !prevState);
	});
	const toogleAreaZoom = () => {
		setShiftActive((prevState) => !prevState);
		if (shiftActive) {
			document.dispatchEvent(new KeyboardEvent("keydown", { shiftKey: true }));
		}
	};

	useEffect(() => {
		map.selectArea.setControlKey(shiftActive);
	}, [map.selectArea, shiftActive]);

	return (
		<button
			title="Zoom in area"
			type="button"
			className="leaflet-control leaflet-touch leaflet-bar a"
			style={{
				background: (shiftActive)? "lightGrey": "",
			}}
			onClick={toogleAreaZoom}
		>
			<ZoomInMapIcon />
		</button>
	);
}

export function AreaSelectToggle() {
	// leaflet-area-select ctrlKey value is inverted unlike shiftKey.
	const [ctrlDeactive, setCtrlDeactive] = useState(true);
	const map = useMap();

	map.on("selectarea:selected", () => {
		setCtrlDeactive((prevState) => !prevState);
	});

	const toggleAreaSelect = () => {
		setCtrlDeactive((prevState) => !prevState);
		if (ctrlDeactive) {
			document.dispatchEvent(new KeyboardEvent("keydown", { key: "ctrl" }));
		}
	};

	useEffect(() => {
		map.selectArea.setControlKey(ctrlDeactive);
	}, [map.selectArea, ctrlDeactive]);

	return (
		<button
			title="Select area"
			type="button"
			className={`leaflet-control leaflet-touch leaflet-bar a`}
			style={{
				background: (!ctrlDeactive) ? "lightGrey" : "",
			}}
			onClick={toggleAreaSelect}
		>
			<HighlightAltIcon />
		</button>
	);
}

type CenterMapAtSelectionProps = {
	selection: L.LatLngBounds;
	options: L.FitBoundsOptions;
}

export function CenterMapAtSelection({ selection, options }: CenterMapAtSelectionProps) {
	const map = useMap();

	const centerMapAtBounds = () => {
		map.flyToBounds(selection, options);
	};

	return (
		<button
			title="Return to selection"
			type="button"
			className="leaflet-control leaflet-touch leaflet-bar a"
			onClick={centerMapAtBounds}
		>
			<FilterCenterFocusIcon />
		</button>
	);
}
