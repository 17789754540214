// Written by: FIT3162 CS Team 1
// Last modified: 6/8/24
// Title: Map selection element

import AreaSelect from "#components/map-selector/AreaSelect";
import { AreaSelectToggle, CenterMapAtSelection } from "#components/map-selector/AreaSelectActions";
import MapToolbar, { LeafletMapPosition } from "#components/map-selector/MapToolbar";
import "#styles/components/MapDisplay";
import "#styles/components/MapSelector";
import L from "leaflet";
import "leaflet-area-select";
import "leaflet/dist/leaflet.css";
import React from "react";
import {
	AttributionControl,
	MapContainer,
	ScaleControl,
	TileLayer,
	Tooltip
} from "react-leaflet";


export const DEFAULT_ZOOM = 10;
const MIN_ZOOM: number = 5;
const MAX_ZOOM: number = 20;
const MAP_COUNT: number = 2;
const [LAT_BOUND, LON_BOUND]: number[] = [90, 180];

/**
 * Decimal degrees to degrees, minutes, seconds
 * @param dd Decimal degrees
 */
function toDMS(dd: number): string {
	const d = Math.floor(dd);
	const m = Math.floor((dd - d) * 60);
	const s = ((dd - d) * 60 - m) * 60;
	return `${d}° ${m}' ${s.toFixed(2)}"`;
}

function getDirectionChar(pos: number, direction: L.Direction) {
	const LAT_DIR = ['top', 'bottom'];
	const LON_DIR = ['left', 'right'];

	if (LAT_DIR.includes(direction)) {
		return pos >= 0 ? 'N' : 'S';
	}
	else if (LON_DIR.includes(direction)) {
		return pos >= 0 ? 'E' : 'W';
	}
	else {
		return ""
	}
}

function CoordLabel({
	pos,
	direction,
}: {
	pos: number;
	direction: L.Direction;
}) {
	const formatPos = toDMS(Math.abs(pos));
	return (
		<Tooltip direction={direction} permanent>
			{`${formatPos} ${getDirectionChar(pos, direction)}`}
		</Tooltip>
	);
}

/**
 * Maps selector element
 * @param {
 *   setBounds,
 * }
 */
export default function MapSelector({ bounds, handleBounds, zoom: initialZoom }: {
	bounds: L.LatLngBounds;
	handleBounds: (zoom: number) => React.Dispatch<L.LatLngBounds>;
	zoom: number;
}) {
	const mapRef = React.useRef<L.Map>(null);
	const maxBounds = new L.LatLngBounds([
		[-LAT_BOUND, -LON_BOUND * MAP_COUNT],
		[LAT_BOUND, LON_BOUND * MAP_COUNT],
	]);
	const boundsOptions: L.FitBoundsOptions = {
		maxZoom: 20,
		padding: [18, 18],
	};

	const setBounds = handleBounds(mapRef.current?.getZoom() || DEFAULT_ZOOM);

	return (
		<MapContainer
			id="map"
			ref={mapRef}
			className="map-container"
			bounds={bounds}
			boundsOptions={boundsOptions}
			maxBounds={maxBounds}
			maxBoundsViscosity={0.5}
			zoom={initialZoom}
			minZoom={MIN_ZOOM}
			maxZoom={MAX_ZOOM}
			preferCanvas // Canvas render (supposed performance improvement)
			attributionControl={false}
		>
			<TileLayer
				url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution={`
					Hosting by <a href='https://opentopography.org'>OpenTopography.org</a><br />
					&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors
				`}
			/>
			<AreaSelect
				selection={bounds}
				setSelection={setBounds}
			/>
			<MapToolbar position={LeafletMapPosition.TOP_RIGHT}>
				<CenterMapAtSelection
					selection={bounds}
					options={boundsOptions}
				/>
				<AreaSelectToggle />
				{/* <AreaZoomToggle /> */}
			</MapToolbar>
			<ScaleControl position="bottomleft" />
			<AttributionControl
				position="bottomright"
				prefix={false}
			/>
		</MapContainer>

	);
}
