// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Edit map page
"use client";

import {
	Breakpoint,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from "@mui/material";
import { PAYMENT_PAGE } from "App";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface ButtonInterface {
  title: string,
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

/**
 * The base modal template
 * @param open 	Boolean if the modal is visible.
 * @param heading	The heading text for the modal.
 * @param children	The content of the modal.
 * #FIXME: This should just take ButtonInterfaces[] and place in the dialog actions
 * @param yesButton	The yes button action for the modal (confirm).
 * @param neutralButton	The neutral button action (cancel).
 */
function BaseModal({ open: isOpen, heading, children: modalContent, yesButton, noButton, neutralButton, maxWidth="sm", dialogActions }: {
  open: boolean,
  heading: string,
  children?: ReactNode,
  yesButton?: ButtonInterface,
  neutralButton?: ButtonInterface,
  noButton?: ButtonInterface,
  maxWidth?: false | Breakpoint
	dialogActions?: React.ReactNode;
}) {
  return (
    <Dialog open={isOpen} fullWidth maxWidth={maxWidth} PaperProps={{ className: "glass--dark solid-fill" }}>
      <DialogTitle>
        {heading}
        <hr />
      </DialogTitle>
      <DialogContent >
        <div style={{ paddingTop: '5px', height: '100%' }}>
          {modalContent}
        </div>
      </DialogContent>
      <DialogActions>
        {neutralButton &&
          <Button onClick={neutralButton.onClick} className="neutral-button">
            {neutralButton.title}
          </Button>}
        {noButton &&
          <Button onClick={noButton.onClick} className="no-button">
            {noButton.title}
          </Button>}
        {yesButton &&
          <Button onClick={yesButton.onClick} className="yes-button">
            {yesButton.title}
          </Button>}
					{dialogActions}
      </DialogActions>
    </Dialog>
  );
}

/**
 * Confirmation modal
 * @param { isOpen, onConfirm, onCancel }
 */
function ConfirmRenderModal({ isOpen, onConfirm, onCancel, userCredits }: {
  isOpen: boolean;
  onConfirm: () => void,
  onCancel: () => void,
  userCredits: number,
}) {

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
      <DialogTitle>Load Area and Render</DialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        <div>
          <p>Loading an area and rendering a shading will cost one Eduard Credit.</p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className="neutral-button" onClick={onCancel}>Cancel</Button>
        <Button className="yes-button" onClick={onConfirm}>Load Area</Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Dialog displayed for insuficient credits.
 * @param isOpen 		Boolean for if the modal dialog is visible.
 * @param onCancel 	Callback function called on clicking cancel
 *
 */
function InsufficientFundsModal({ isOpen, onCancel }: {
  isOpen: boolean;
  onCancel: () => void;
}) {
  const navigate = useNavigate();
  const handlePurchaseCredits = () => navigate(PAYMENT_PAGE);

  return (
    <BaseModal
      open={isOpen}
      heading="Insufficient Credits"
      maxWidth="xs"
      neutralButton={{ title: "Cancel", onClick: onCancel }}
      yesButton={{ title: "Purchase Credits", onClick: handlePurchaseCredits }} >
      <div>
        <p>Eduard credits are required to render a shading.</p>
      </div>
    </BaseModal>
  );
}


/**
 * Dialog warning the user reaching the maximum number of demo renders.
 * @param open 			Boolean for if the modal visible.
 * @param onCancel 	Callback function called on clicking close.
 */
function MaxDemoRendersModal({ open: isOpen, onClose }: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <BaseModal
      open={isOpen}
      heading="Maxmimum Demo Shadings"
      neutralButton={{ title: "Close", onClick: onClose }} >
      <p>
        The maximum number of demo shadings has been reached.
        Try again later or sign in with your Google account and purchase shading credits.
      </p>
    </BaseModal>
  );
}


export {
	BaseModal,
	ConfirmRenderModal,
	InsufficientFundsModal,
	MaxDemoRendersModal
};

