import { CreditsProvider } from "#components/Contexts";
import ThemeController from "#components/ThemeController";
import { awakenModal } from "#libs/apis/backend";
import EditMapPage from "#pages/EditMapPage";
import PaymentPage from "#pages/PaymentPage";
import ShadingsPage from "#pages/ShadingsPage";
import UserProfilePage from "#pages/UserProfile";
import React from 'react';
import {
	RequireAuth as ReactRequireAuth,
	useAuthUser,
	useIsAuthenticated
} from 'react-auth-kit';
import { Flags } from 'react-feature-flags';
import {
	Route,
	BrowserRouter as Router,
	Routes,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

/**
 * Constants for page links
 */
const EDIT_MAP_PAGE = "/";
const DOWNLOAD_MAP_PAGE = "/download-map";
const PAYMENT_PAGE = "/credits";
const USER_PROFILE_PAGE = "/profile";
const SHADINGS_PAGE = "/shadings";
const INFO_PAGE = "https://eduard.earth/cloud/info";
const HELP_PAGE = "https://eduard.earth/cloud/help";
const UNSUPPORTED_PAGE = "https://eduard.earth/cloud/unsupported";
const PRIVACY_POLICY_PAGE = "http://eduard.earth/#privacy";
const ABOUT_PAGE = "http://eduard.earth/";

/**
 * Safeguard the children with authentication
*/
function RequireAuth({children}: { children: JSX.Element}) {
  return (
    <Flags authorizedFlags={['enableAuthentication']}
      renderOn={() => <ReactRequireAuth loginPath={'/'}>{children}</ReactRequireAuth>}
      renderOff={() => children}
    />
  );
}


// Define the app page routes
function App() {
  const authData = useAuthUser();
  const isAuthenticated = useIsAuthenticated();

  React.useEffect(() => {
    // Awaken modal on signin
    if (isAuthenticated()) {
      awakenModal();
    }
  }, [authData, isAuthenticated])

  return (
    <ThemeController>

			<Router>
				<CreditsProvider>
					<Routes>
						<Route path={EDIT_MAP_PAGE} element={<EditMapPage/>} />
						<Route path={PAYMENT_PAGE} element={<RequireAuth><PaymentPage/></RequireAuth>} />
						<Route path={USER_PROFILE_PAGE} element={<RequireAuth><UserProfilePage/></RequireAuth>} />
						<Route path={SHADINGS_PAGE} element={<RequireAuth><ShadingsPage/></RequireAuth>} />
					</Routes>
				</CreditsProvider>
			</Router>
    </ThemeController>
  );
}

export default App;
export {
	DOWNLOAD_MAP_PAGE, EDIT_MAP_PAGE, HELP_PAGE, INFO_PAGE, PAYMENT_PAGE, PRIVACY_POLICY_PAGE, SHADINGS_PAGE, UNSUPPORTED_PAGE, USER_PROFILE_PAGE
};

