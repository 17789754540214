// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Project class definintion

import { PostGIS_ST_Area } from "#libs/geo";
import { Bounds, ProcessingSettings } from "#libs/types";


// Digital elevation models from OpenTopography
export class ElevationModel {
	public readonly name: string;
	// OpenTopography demtype: https://portal.opentopography.org/apidocs/#/Public/getGlobalDem
	public readonly demtype: string;
	// Maximum selectable area from OpenTopography /globaldem API
	public readonly maxSelectableArea: number;  // km^2

	constructor(name: string, demtype: string, maxSelectableArea: number) {
		this.name = name;
		this.demtype = demtype;
		this.maxSelectableArea = maxSelectableArea;
	}

	isValidBounds(bounds: L.LatLngBounds): boolean {
		return PostGIS_ST_Area(bounds) < this.maxSelectableArea;  // km^2
	}
}

export class ElevationModelRegistry {
	private static _instance: ElevationModelRegistry = new ElevationModelRegistry();
	private static _map: Map<string, ElevationModel> = new Map<string, ElevationModel>();

	constructor() {
		if (ElevationModelRegistry._instance) {
			throw new Error("Error: Instantiation failed: Use ElevationModelRegistry.getInstance() instead of new.");
		}
		ElevationModelRegistry._instance = this;
	}

	public static getInstance(): ElevationModelRegistry {
		return ElevationModelRegistry._instance;
	}

	public static register(elevationModel: ElevationModel) {
		ElevationModelRegistry._map.set(elevationModel.demtype, elevationModel);
	}

	public static get(demtype: string): ElevationModel | null {
		return ElevationModelRegistry._map.get(demtype) ?? null;
	}

	public static list(): ElevationModel[] {
		return Array.from(ElevationModelRegistry._map.values());
	}
}

const AW3D30 = new ElevationModel("ALOS 30 m", "AW3D30", 450_000);
const NASADEM = new ElevationModel("NASADEM 30 m", "NASADEM", 450_000);
const SRTMGL1 = new ElevationModel("SRTM 30 m", "SRTMGL1", 450_000);
const SRTMGL3 = new ElevationModel("SRTM 90 m", "SRTMGL3", 4_050_000);

ElevationModelRegistry.register(AW3D30);
ElevationModelRegistry.register(NASADEM);
ElevationModelRegistry.register(SRTMGL1);
ElevationModelRegistry.register(SRTMGL3);

export const DEFAULT_ELEVATION_MODEL = AW3D30;


export enum Projection {
	MERCATOR = "Mercator",
	GEOGRAPHIC = "Geographic"
}

export const DEFAULT_PROJECTION = Projection.MERCATOR;

export const DEFAULT_PROJECT_NAME = "Untitled map"

export class Project {
	_bounds: Bounds | undefined;
	_settings: ProcessingSettings = {
		modelNo: 1,
		lightRotation: 0,
		generalization: 0,
		generalizationDetails: 0,
		aerialPerspective: 50,
		slopeDarkness: 30,
		elevationRangeMin: 0,
		elevationRangeMax: 70,
		flatAreaAmount: 0,
		flatAreaSize: 0,
	};
	_isDemo: boolean = false;
	_elevationModel: ElevationModel = DEFAULT_ELEVATION_MODEL;
	_projection: Projection = DEFAULT_PROJECTION;
	_mapId: string = "";
	_projectName: string = "";

	constructor() { }

	get isDemo(): boolean {
		return this._isDemo;
	}
	set isDemo(isDemo: boolean) {
		this._isDemo = isDemo;
	}

	set elevationModel(model: ElevationModel) {
		this._elevationModel = model;
	}
	get elevationModel(): ElevationModel {
		return this._elevationModel;
	}

	set projection(proj: Projection) {
		this._projection = proj;
	}
	get projection(): Projection {
		return this._projection;
	}

	set projectName(name: string) {
		this._projectName = name;
	}
	get projectName(): string {
		return this._projectName;
	}

	set mapId(id: string) {
		this._mapId = id;
	}
	get mapId(): string {
		return this._mapId;
	}

	get bounds(): any {
		return this._bounds;
	}
	get settings(): any {
		return this._settings;
	}

	set bounds(bounds: L.LatLngBounds) {
		this._bounds = {
			north: bounds.getNorth(),
			south: bounds.getSouth(),
			east: bounds.getEast(),
			west: bounds.getWest(),
		};
	}

	set settings(processSettings: ProcessingSettings) {
		this._settings = processSettings;
	}

	reset(): void {
		this._bounds = undefined;
		this._settings = {
			modelNo: 1,
			lightRotation: 0,
			generalization: 0,
			generalizationDetails: 0,
			aerialPerspective: 50,
			slopeDarkness: 30,
			elevationRangeMin: 0,
			elevationRangeMax: 70,
			flatAreaAmount: 0,
			flatAreaSize: 0,
		};
		this.isDemo = false;
		this._elevationModel = DEFAULT_ELEVATION_MODEL;
		this._projection = DEFAULT_PROJECTION;
		this._mapId = "";
		this._projectName = DEFAULT_PROJECT_NAME;
	}
}


export default Project;
