// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Project bar element

import logo from "#assets/logo.png";
import { CreditsContext } from "#components/Contexts";
import AccountMenu from "#components/appbar/AccountMenu";
import { SITE_SUBTITLE, SITE_TITLE } from "#libs/constants";
import "#styles/components/ProjectBar";
import { TollTwoTone as TollIcon } from "@mui/icons-material";
import { AppBar, Box, Link, Toolbar } from "@mui/material";
import { EDIT_MAP_PAGE, PAYMENT_PAGE } from "App";
import React from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { Flags } from 'react-feature-flags';
import { useNavigate } from "react-router-dom";


/**
 * Drop down menu for account options
 * @param userCredits Number of credits the user has
 * @param isAuthenticated Whether the user is authenticated
 */
function AccountDisplay({ isAuthenticated }: { isAuthenticated: boolean }) {
  const navigate = useNavigate();
	const { userCredits } = React.useContext(CreditsContext);

  const handleClickCredits = () => navigate(PAYMENT_PAGE);

  return (
    <div style={{display: "flex", justifyContent: "end", minWidth: 150}}>
      {/* User credit balance */}
      {isAuthenticated ? (
        <div onClick={handleClickCredits} className="clickable mx-2" style={{display: "flex", alignItems: "center"}} >
          <TollIcon fontSize="medium" style={{marginInline: 10}}/>
          <span style={{fontSize: 18 }}>{userCredits}</span>
        </div>
      ) : (
        <div className="clickable mx-2" style={{display: "flex", alignItems: "center"}}>
          <span style={{fontSize: 18 }}>Sign in</span>
        </div>
      )}
      {/* Account menu */}
      <section className="mx-1" style={{justifyContent: "center"}}>
        <AccountMenu />
      </section>
    </div>
  );
}


function HeaderLogo() {
	return (
		<Link
			href={EDIT_MAP_PAGE}
			className="nav__title"
			underline="none" >
			<img className="nav__logo" src={logo} alt="Eduard icon" />
			<h6 style={{fontSize: "1.4em", marginBottom: 0, display: "flex", alignItems: "center"}}>{SITE_TITLE}</h6>
			<span className="nav__subtitle">{SITE_SUBTITLE}</span>
		</Link>
	);
}


function ProjectBar({ children }: { children: React.ReactNode}) {
  const isAuthenticated = useIsAuthenticated();
  return (
		<AppBar className="nav">
			<Toolbar variant="dense" className="nav__toolbar glass--dark">
				<HeaderLogo />
				<Box component="div" width="100%">{children}</Box>
				<Flags authorizedFlags={['enableAuthentication']}>
					<AccountDisplay isAuthenticated={isAuthenticated()} />
				</Flags>
			</Toolbar>
		</AppBar>
  );
}

export default ProjectBar;
