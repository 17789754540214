import AttributionNotice from "#components/AttributionNotice";
import ProjectBar from "#components/appbar/ProjectBar";
import { Box } from "@mui/material";
import React from "react";

type PageLayoutProps = { children: React.ReactNode };


export function PageLayout({ children }: PageLayoutProps) {
	return (
		<>
			<ProjectBar>
				<Box component="div" id="project-bar-content" />
			</ProjectBar>
			<Box
				component="div"
				width="100vw"
				height="100vh"
				paddingTop="calc(var(--nav-height))"
				bgcolor="var(--bg-color)"
			>
				{children}
			</Box>
			<footer>
				<AttributionNotice />
			</footer>
		</>
	);
}

export default PageLayout;
