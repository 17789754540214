import CreateShadingModal from "#components/modals/CreateShadingModal";
import { InsufficientFundsModal } from "#components/modals/modals";
import { awakenModal, getPurchaseRatio } from "#libs/apis/backend";
import { CreditsRatio } from "#libs/types";
import "#styles/components/Tileset";
import {
	ImageOutlined as DemoDataIcon,
	CenterFocusStrongRounded as DownloadForOfflineOutlinedIcon,
	AutoStoriesRounded as GuideIcon,
	HelpOutline as HelpOutlineIcon,
	AddPhotoAlternateOutlined as NewShadingIcon,
	BurstModeSharp as ShadingsIcon,
	TollTwoTone as TollIcon,
	UploadFile as UploadFileIcon,
} from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { DOWNLOAD_MAP_PAGE, EDIT_MAP_PAGE, HELP_PAGE, INFO_PAGE, PAYMENT_PAGE, SHADINGS_PAGE } from "App";
import jwt_decode, { JwtPayload } from "jwt-decode";
import React, { useState } from "react";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useShadingStore } from "#components/store";

/**
 * The base tile template format.
 * @param title 		Title label of the tile
 * @param onClick		Function handler on clicking the tile.
 * @param children 	Icon used for the tile.
 */
function BaseTile({ title, onClick, children, disabled = false }: {
	title: string,
	onClick: () => void,
	children?: React.ReactNode
	disabled?: boolean
}) {

	return (
		<IconButton className="base-tile__button" onClick={onClick} disabled={disabled}>
			<div className="base-tile__icon">{children}</div>
			<h6 className="base-tile__label">{title}</h6>
		</IconButton>
	);
}

/**
 * Row to display tiles
 * @param props
 */
function TileRow(props: { children: React.ReactNode }) {
	return (
		<section className="tiles tile-row">{props.children}</section>
	);
}

/**
 * Tile to display the getting started modal.
 * Handles displaying Getting started modal on launch + clicking tile.
 */
function GettingStartedTile() {
	const handleTileClick = () => {
		window.open(INFO_PAGE, '_blank', 'noopener,noreferrer');
	};

	return (
		<BaseTile title="Getting Started" onClick={handleTileClick}>
			<HelpOutlineIcon />
		</BaseTile>
	);
}

/**
 * Tile to redirect to download grid page (OpenTopography).
 */
function SelectAreaTile() {
	const navigate = useNavigate();

	const handleClick = () => navigate(DOWNLOAD_MAP_PAGE);

	return <>
		<BaseTile title="Select Area" onClick={handleClick}>
			<DownloadForOfflineOutlinedIcon />
		</BaseTile>
	</>
}

/***
 * Tile to load demo data.
*/
function TryDemoTile() {
	const shadingStore = useShadingStore();
	const navigate = useNavigate();

	const handleClick = () => {
		awakenModal();
		shadingStore.loadDemoShading();
		navigate(EDIT_MAP_PAGE);
	}

	return (
		<BaseTile title="Try Demo" onClick={handleClick}>
			<DemoDataIcon />
		</BaseTile>
	);
}

/**
 * Modal displaying pricing structure of application.
 */
function UploadFileTile() {
	return (
		<BaseTile title="Upload Grid" onClick={() => { }} disabled={true}>
			<UploadFileIcon />
		</BaseTile>
	);
}

/**
 * Modal displaying pricing structure of application
 */
function CostModal({ open: isOpen, onClose }: { open: boolean, onClose: () => void }) {
	const [creditsRatio, setRatio] = useState<CreditsRatio>();
	const [isRequesting, setRequesting] = useState(true);

	// Hook to fetch dialog from server.
	React.useEffect(() => {
		const fetchCreditsRatio = async () => {
			if (isOpen) {
				try {
					setRequesting(true);
					const creditsRatio = await getPurchaseRatio();
					setRatio(creditsRatio);
				} catch {
					console.error("Unable to fetch prices from the server.");
				} finally {
					setRequesting(false);
				}
			}
		};
		fetchCreditsRatio();
	}, [isOpen]);

	const ratio = creditsRatio ? (creditsRatio.credits / creditsRatio.cost).toFixed(2) : null;

	const renderDialogContent = () => {
		if (isRequesting) return null;

		// Error dialog if cant request server
		if (!creditsRatio) {
			return (
				<>
					<h1>Unable to Fetch Prices</h1>
					<hr />
					<div style={{ paddingInline: 30 }}>
						<p>Unable to fetch pricing from the server. Please try again later.</p>
					</div>
				</>
			);
		}

		// Credits dialog displaying prices
		return (
			<>
				<h1>${ratio} per image</h1>
				<p>
					Purchase credits to create beautiful shaded relief images.
					Creating and adjusting will cost 1 Eduard credit each instance.
				</p>
				<hr />
				<div style={{ paddingInline: 30 }}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<p>{`${creditsRatio.credits} render${creditsRatio.credits > 1 ? "s" : ""}`}</p>
						<div style={{ display: "flex", justifyContent: "space-between", gap: 20 }}>
							<span>{`$ ${creditsRatio.cost.toFixed(2)}`}</span>
							<span>{`${ratio} / image`}</span>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<Dialog open={isOpen && !isRequesting} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark" }}>
			<DialogTitle>Purchase Credits</DialogTitle>
			<DialogContent sx={{ overflow: "hidden" }}>
				{renderDialogContent()}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} style={{ color: "var(--link-color)" }}>Close</Button>
			</DialogActions>
		</Dialog>
	);
}

/**
 * Displays pricing modal or redirect to purchase credits page.
 */
function CreditsTile() {
	const [showCostModal, setShowCostModal] = useState<boolean>(false);

	const navigate = useNavigate();
	const isAuthenticated = useIsAuthenticated();
	const handleClick = () => isAuthenticated() ? navigate(PAYMENT_PAGE) : setShowCostModal(true);

	return <>
		<BaseTile title="Credits" onClick={handleClick}>
			<TollIcon />
		</BaseTile>
		<CostModal open={showCostModal} onClose={() => setShowCostModal(false)} />
	</>;
}

/**
 * Opens help page
 */
function UserGuideTile() {
	const handleClick = () => {
		window.open(HELP_PAGE, '_blank', 'noopener,noreferrer');
	};

	return (
		<BaseTile title="User Guide" onClick={handleClick}>
			<GuideIcon />
		</BaseTile>
	);
}

/***
 * Redirects the user to the user shadings page,
 */
function ShadingsTile() {
	const navigate = useNavigate();
	const handleClick = () => navigate(SHADINGS_PAGE);

	return (
		<BaseTile title="Shadings" onClick={handleClick}>
			<ShadingsIcon />
		</BaseTile>
	);
}

/**
 * Displays the create new shading project dialog.
 */
function NewShadingTile({ createShadingCallback, credits: userCredits }: {
	createShadingCallback: (arg0: string) => void
	credits: number
}) {
	const [isShadingModalOpen, setShadingModalOpen] = useState<boolean>(false);
	const [isCreditsModalOpen, setCreditsModalOpen] = useState<boolean>(false);

	// Shows the new shading dialog
	const handleTileClick = () => {
		if (userCredits > 0) {
			setShadingModalOpen(true);
		}
		else {
			setCreditsModalOpen(true);
		}
	};
	// Creates a new shading project
	const handleNewShading = (shadingName: string) => {
		closeModals();
		createShadingCallback(shadingName);
	};

	// Close the modal dialog
	const closeModals = () => {
		setShadingModalOpen(false);
		setCreditsModalOpen(false);
	};

	return <>
		<BaseTile title="New Shading" onClick={handleTileClick}>
			<NewShadingIcon />
		</BaseTile>
		<CreateShadingModal open={isShadingModalOpen} onConfirm={handleNewShading} onCancel={closeModals} />
		<InsufficientFundsModal isOpen={isCreditsModalOpen} onCancel={closeModals} />
	</>
}

/**
 * Google auth panel to sign into account.
 */
function SignInPanel() {
	//Callback function from logging out
	const signIn = useSignIn();

	const handleSignIn = (response: any) => {
		const decoded = jwt_decode<JwtPayload>(response.credential); // Decode the values from the jwt response

		if (
			"email" in decoded &&
			"name" in decoded &&
			"sub" in decoded &&
			decoded.sub) {

			signIn({
				token: response.credential,  // Sign in token, not user tokens
				expiresIn: 1440,
				tokenType: "Bearer",
				authState: {
					authKey: decoded.sub,
					email: decoded.email,
					name: decoded.name,
				},
			})
		}
	}

	return (
		<div style={{ margin: 20 }}>
			<GoogleLogin
				onSuccess={handleSignIn}
				onError={() => { console.log("Login Failed"); }}
				useOneTap
				// theme="filled_blue"
				shape="rectangular"
				theme="filled_blue"
				width="350"
				text="signin_with"
			/>
			<br />
			<p style={{ textAlign: 'center' }}>
				Don't have a Google account? <a href="https://accounts.google.com/signup" target="_blank">Create one here</a>.
			</p>
		</div>
	)
}

/**
 * Navigation tileset to visit load tiles
 */
function NavigationTileset({ rowSize, children = [] }: {
	rowSize: number,
	children?: React.ReactNode[]
}) {
	const isAuthenticated = useIsAuthenticated();
	children = children.filter(value => !!value);

	// Login header text
	const loginText = "Eduard creates beautiful shaded relief images for your maps.";
	const authenticatedText = "";

	// Place the cards into the card rows
	const tileRows = Array.from({ length: Math.ceil(children.length / rowSize) }, (_, i) => (
		<TileRow key={i}>
			{children.slice(i * rowSize, i * rowSize + rowSize)}
		</TileRow>
	));

	return (
		<div className="tiles__container" style={{ alignItems: 'center' }}>
			<p>{isAuthenticated() ? authenticatedText : loginText}</p>
			<div className="tiles tiles__container">{tileRows}</div>
			{!isAuthenticated() && <SignInPanel />}
		</div>
	);
}

export default NavigationTileset;
export {
	BaseTile, CreditsTile, GettingStartedTile, NewShadingTile, SelectAreaTile, ShadingsTile, TryDemoTile,
	UploadFileTile,
	UserGuideTile
};

