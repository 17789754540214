import { Backdrop } from "@mui/material";
import { ProgressSpinner } from "#components/ProgressStatus";
import { createPortal } from "react-dom";

/***
 * Greyed out backdrop preventing action whilst downloading from OpenTopography
 * @param isActive: Flag if download flag is active
 */
export default function LoadingAreaBackdrop({ isActive }: { isActive: boolean }) {
	const backdropComponent = (
		<Backdrop
			open={isActive}
			sx={{
				color: '#fff',
				// zIndex: 2000,  // FIXME: currently renders behind backdrop in navbar
				zIndex: (theme) => theme.zIndex.modal + 1,
			}} >
			<ProgressSpinner text="Loading" />
		</Backdrop>
	)

	return createPortal(backdropComponent, document.body);
}
