/**
 * Digital Elevation Model drop down selction for OpenTopography.
 */
import { DEFAULT_ELEVATION_MODEL, ElevationModel, ElevationModelRegistry } from "#libs/Project";
import { InputLabel, MenuItem, SelectProps, TextField } from "@mui/material";
import { ChangeEvent } from "react";


// Digital elevation models
interface DEMDropdownProps {
	elevationModel: ElevationModel,
	onChange: (arg0: ElevationModel) => void,
	SelectProps?: Partial<SelectProps> | undefined,
	defaultValue?: ElevationModel,
}

function DEMDropdown({
	elevationModel,
	onChange,
	SelectProps,
	defaultValue = DEFAULT_ELEVATION_MODEL,
}: DEMDropdownProps
) {
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const selectedDEM = ElevationModelRegistry.get(e.target.value);
		if (selectedDEM) {
			onChange(selectedDEM);
		}
	};

	const DEMmenuItems = ElevationModelRegistry.list().map((elevationModel: ElevationModel) => {
		return (
			<MenuItem value={elevationModel.demtype}>{elevationModel.name}</MenuItem>
		);
	});

	return (
		<TextField
			select
			SelectProps={SelectProps}
			inputProps={{ id: 'elevationModel' }}
			defaultValue={defaultValue.demtype}
			value={elevationModel.demtype}
			onChange={handleChange}
			fullWidth
			label="Elevation Model"
			margin="dense"
			size="small" >
			<InputLabel sx={{ paddingX: 2, opacity: 0.5 }}>Elevation Model</InputLabel>
			{DEMmenuItems}
		</TextField>
	);
}

export default DEMDropdown;
