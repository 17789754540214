import { ProcessingSettings } from "#libs/types";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware"

export const DEFAULT_PROJECT_NAME = "Untitled map"
const createDefaultSettingsFactory = () => ({
	modelNo: 1,
	lightRotation: 0,
	generalization: 0,
	generalizationDetails: 0,
	aerialPerspective: 50,
	slopeDarkness: 30,
	elevationRangeMin: 0,
	elevationRangeMax: 70,
	flatAreaAmount: 0,
	flatAreaSize: 0,
});

export interface AppState {
	shadingId: string | null;
	name: string | null;
	bounds: L.LatLngBounds | null;
	processingSettings: ProcessingSettings;
	isDemo: boolean;
};

interface AppStateActions {
	loadShading: (shadingId: string, name: string, bounds: L.LatLngBounds, processingSettings?: ProcessingSettings) => void;
	loadDemoShading: () => void;
	clearShading: () => void;
	setProcessingSettings: (processingSettings: ProcessingSettings) => void;
}

export const useShadingStore = create<AppState & AppStateActions>(
	(set, _get) => ({
		shadingId: null,
		name: null,
		bounds: null,
		processingSettings: createDefaultSettingsFactory(),
		isDemo: false,

		loadShading: (shadingId: string, name: string, bounds: L.LatLngBounds, processingSettings?: ProcessingSettings) => {
			set((_state: AppState) => {
				const newProcessingSettings = (processingSettings !== undefined)
					? processingSettings
					: createDefaultSettingsFactory();

				return {
					shadingId,
					name,
					bounds,
					processingSettings: newProcessingSettings,
					isDemo: false,
				};
			});
		},

		loadDemoShading: () => {
			set((_state: AppState) => ({
				shadingId: null,
				name: null,
				bounds: null,
				processingSettings: createDefaultSettingsFactory(),
				isDemo: true,
			}));
		},

		clearShading: () => {
			set((_state: AppState) => ({
				shadingId: null,
				name: null,
				bounds: null,
				processingSettings: createDefaultSettingsFactory(),
				isDemo: false,
			}));
		},

		setProcessingSettings: (processingSettings: ProcessingSettings): void => {
			set((state: AppState) => ({
				...state,
				processingSettings,
			}));
		}
	})
);
