"use client";

import { CreditsContext } from "#components/Contexts";
import MapDisplay from "#components/MapDisplay";
import { LabelledProgressSpinner } from "#components/ProgressStatus";
import NavigationTileset, {
	CreditsTile,
	GettingStartedTile,
	NewShadingTile,
	ShadingsTile,
	TryDemoTile,
	UserGuideTile
} from "#components/Tileset";
import MapProperties from "#components/map-properties/MapProperties";
import CreateShadingModal from "#components/modals/CreateShadingModal";
import {
	InsufficientFundsModal,
	MaxDemoRendersModal,
} from "#components/modals/modals";
import { AppState, useShadingStore } from "#components/store";
import { ImageFormat, zipShading } from "#libs/ImageExports";
import { downloadDemoShading, downloadShading, getProgress, processCachedTiff, renderDemoShading, startRequest } from "#libs/apis/backend";
import { MAX_DEMO_RENDERS } from "#libs/constants";
import { COOKIE_USER_DEMO_RENDERS } from "#libs/sessionStorageKeys";
import { ShadingRender, ProcessingSettings, Render } from "#libs/types";
import { User } from "#libs/user";
import { getCookie, setCookie } from "#libs/utils";
import PageLayout from "#pages/PageLayout";
import "#styles/glass";
import "#styles/pages/EditMapPage";
import {
	Cached as CachedIcon,
	FileDownloadOutlined as FileDownloadOutlinedIcon,
	AutoStoriesRounded as GuideIcon,
	AddPhotoAlternateOutlined as NewShadingIcon,
	BurstModeSharp as UserShadingsIcon,
} from "@mui/icons-material";
import {
	Box,
	Button,
	InputLabel,
	Menu,
	MenuItem,
	Stack,
	Tooltip,
	useMediaQuery
} from "@mui/material";
import { HELP_PAGE, SHADINGS_PAGE } from "App";
import "axios";
import { AxiosError } from "axios";
import React, { Dispatch, useCallback, useContext, useEffect, useState } from "react";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";


/**
 * Display title for the shading project.
 * FIXME: currently hacky way to center title, should look at more consistent manner to centering
 * @param shading   The shading project currently displayed
 */
type ShadingTitleProps = { title: string | null };
function ShadingTitle({ title }: ShadingTitleProps) {
	const isTitleVisible = useMediaQuery('(min-width:1000px)');
	if (title === null || !isTitleVisible) return null;

	return (
		<section
			style={{
				display: 'flex',
				textAlign: 'center',
				position: 'absolute',
				flexDirection: 'column',
				width: '100%',
				translate: '1% 13px',
				visibility: isTitleVisible ? "visible" : "hidden",
			}}>
			<h6 className="property-heading">{title}</h6>
		</section>
	);
}


/**
 * Button for displaying the create shading dialog.
 * @param disabled  Allows clicking on the create shading button.
 */
function CreateShadingButton({ createShadingCallback, disabled: isDisabled, credits: currentCredits }: {
	createShadingCallback: (arg0: string) => void,
	disabled: boolean,
	credits: number,
}) {
	const auth = useAuthUser();
	const authKey = auth()?.authKey;

	const [showShadingModal, setShowShadingModal] = useState<boolean>(false);
	const [showCreditsModal, setShowCreditsModal] = useState<boolean>(false);

	const onClick = () => {
		if (currentCredits > 0) {
			setShowShadingModal(true);
		}
		else {
			setShowCreditsModal(true);
		}
	};

	const closeShadingModal = () => setShowShadingModal(false);
	const closeCreditsModal = () => setShowCreditsModal(false);

	const createNewShading = (newShadingName: string) => {
		closeShadingModal();
		createShadingCallback(newShadingName);
	}

	return <>
		<Tooltip title="Create a new shading." placement="bottom-start">
			<Button
				onClick={onClick}
				disabled={!authKey && isDisabled}
				className="icon-button m-2"
				variant="outlined"
				color="primary">
				<NewShadingIcon />
			</Button>
		</Tooltip>
		<CreateShadingModal open={showShadingModal} onConfirm={createNewShading} onCancel={closeShadingModal} />
		<InsufficientFundsModal isOpen={showCreditsModal} onCancel={closeCreditsModal} />
	</>
}


/**
 * Drop down menu for available shading download formats.
 */
function DownloadFormatMenu({ anchorEl, isOpen, handleClose, handleMenuItemClick }: {
	anchorEl: HTMLElement | null,
	isOpen: boolean,
	handleClose: () => void,
	handleMenuItemClick: (format: ImageFormat) => void,
}) {
	const imageFormatItems: JSX.Element[] = (
		Object.keys(ImageFormat) as Array<ImageFormat>
	).map((format: ImageFormat) => (
		<MenuItem onClick={() => handleMenuItemClick(format)}>
			{ImageFormat[format as keyof typeof ImageFormat]}
		</MenuItem>
	));

	return (
		<Menu
			id="demo-positioned-menu"
			aria-labelledby="demo-positioned-button"
			anchorEl={anchorEl}
			open={isOpen}
			onClose={handleClose}
			anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
			transformOrigin={{ horizontal: "center", vertical: "top" }}
		>
			<InputLabel sx={{ paddingX: 2, opacity: 0.5 }}>Download Shading</InputLabel>
			{imageFormatItems}
		</Menu>
	);
}


/**
 * Download selection for the shading render.
 * @param shading   The shading render to be downloaded.
 * @param filename  Name of the downloaded file (based upon the shading project name).
 * @param disabled  Allows clicking on the download button.
 * FIXME: Currently we dont differentiate between different demo shadings from different users (this should be handled with a request ID.)
 */
type DownloadShadingButtonProps = {
	filename: string | null;
	shadingId: string | null;
	disabled: boolean;
	// FIXME: Modal should handle errors in this component
	errorOpen: boolean;
	setErrorOpen: Dispatch<boolean>;
	setErrorMessage: Dispatch<string>;
}
function DownloadShadingButton({
	filename,
	shadingId,
	disabled,
	errorOpen,
	setErrorOpen,
	setErrorMessage
}: DownloadShadingButtonProps)
{
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorEl);
	const openMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
	const closeMenu = () => setAnchorEl(null);

	const auth = useAuthUser();
	const authData = auth();
	const user = { authKey: authData?.authKey, email: authData?.email, name: authData?.name };
	const shadingStore = useShadingStore.getState();

	const downloadShadingWithFormat = async (filetype: ImageFormat) => {
		if (disabled)
			throw Error("The download shading button is disabled");
		if (filename === null)
			throw Error("No filename provided for download");
		if (shadingId === null && !shadingStore.isDemo)
			throw Error("No shading to download");

		closeMenu();
		try {
			const render: Render = (shadingId !== null)
				? await downloadShading(user, shadingId, filetype)
				: await downloadDemoShading(filetype, shadingStore.processingSettings);
			zipShading(render, filename);
		}
		catch (error: unknown) {
			setErrorMessage("Failed to download render, please try again later.")
			setErrorOpen(true);
		}
	};

	return <>
		<Tooltip title="Download the shading." placement="bottom-start">
			<Button
				onClick={openMenu}
				disabled={disabled}
				className="icon-button m-2"
				color="primary"
				variant="outlined" >
				<FileDownloadOutlinedIcon />
			</Button>
		</Tooltip>
		<DownloadFormatMenu
			anchorEl={anchorEl}
			isOpen={isMenuOpen}
			handleClose={closeMenu}
			handleMenuItemClick={downloadShadingWithFormat}
		/>
	</>
}


/**
 * Download selection for the shading render.
 * @param shading   The shading render to be downloaded.
 * @param filename  Name of the downloaded file (based upon the shading project name).
 * @param disabled  Allows clicking on the download button.
 */
function ShowGuideButton({ disabled: isDisabled }: {
	disabled: boolean
}) {
	const showGuide = () => { window.open(HELP_PAGE, '_blank', 'noopener,noreferrer'); };

	return <>
		<Tooltip title="See the user guide." placement="bottom-start">
			<Button
				onClick={showGuide}
				disabled={isDisabled}
				className="icon-button m-2"
				color="primary"
				variant="outlined" >
				<GuideIcon />
			</Button>
		</Tooltip>
	</>
}


function UserShadingButton({ disabled: isDisabled }: {
	disabled: boolean,
}) {
	const navigate = useNavigate();
	return <>
		<Tooltip title="Shadings" placement="bottom-start">
			<Button
				onClick={() => navigate(SHADINGS_PAGE)}
				disabled={isDisabled}
				className="icon-button m-2"
				color="primary"
				variant="outlined" >
				<UserShadingsIcon />
			</Button>
		</Tooltip>
	</>
}


/**
 * Error message modal
 * @param { isOpen, errorMessage, onCancel }
 * @returns  Element
 */
function RenderErrorModal({ open: isOpen, errorMessage, onCancel }: {
	open: boolean;
	errorMessage: string | null;
	onCancel: () => void;
}) {
	if (!isOpen) return null;

	return (
		<div className="modal-overlay">
			<div className="popup">
				<h2>Render Failed</h2>
				<p>{errorMessage}</p>
				<p>Please try again.</p>
				<div>
					<button className="no-button" onClick={onCancel}>OK</button>
				</div>
			</div>
		</div>
	);
}


/**
 * Adjust shading settings for render
 * @param setSettings
 * @returns
 */
function ShadingAdjustmentsPanel({ setSettings, disabled }: {
	setSettings: React.Dispatch<ProcessingSettings>
	disabled: boolean
}) {
	return (
		<div className="settings-panel glass--dark page-content" style={{ width: "300px", overflowY: 'clip' }}>
			<Stack className="map-panel" spacing={0.5} sx={{ whiteSpace: "nowrap" }}>
				<MapProperties callback={setSettings} disabled={disabled} />
			</Stack>
		</div>
	);
}


/**
 * Edits map page
 */
function EditMapPage(): JSX.Element {
	const auth = useAuthUser();
	const user = auth() as User | null;
	const isAuthenticated = useIsAuthenticated();

	const { userCredits } = useContext(CreditsContext);
	const shadingStore = useShadingStore.getState();

	const shadingFilename = shadingStore.isDemo ? "Eduard Cloud Demo" : shadingStore.name;
	const [requestId, setRequestId] = useState<string | null>(null);
	const [progress, setProgress] = useState<number>(0);
	const [shadingRender, setRenderUrl] = useState<ShadingRender | null>(null);

	// Page rendering state
	const [hasUpdatedProject, setHasUpdatedProject] = useState<boolean>(false);
	const [isRendering, setRendering] = useState<boolean>(false);

	const hasLoadedShading = isAuthenticated() ? shadingStore.shadingId : shadingStore.isDemo;
	const hasShadingRenderData = shadingRender !== null;

	const isControlsDisabled = isRendering || !hasLoadedShading;
	const isCreateShadingDisabled = isRendering || !isAuthenticated();
	const canDownloadShading = hasShadingRenderData && !isControlsDisabled;
	const canRerenderShading = hasLoadedShading && hasUpdatedProject && !isRendering && !isControlsDisabled;

	const [errorMessage, setErrorMessage] = useState<string>("");
	const [showRenderErrorModal, setShowRenderErrorModal] = useState<boolean>(false);
	const [showMaxDemoRendersModal, setShowMaxDemoRendersModal] = useState<boolean>(false);


	const handleCloseMaxDemoRenders = () => setShowMaxDemoRendersModal(false);

	/* Function to handle render fail.*/
	const handleRenderFail = useCallback((e: AxiosError) => {
		setErrorMessage(`Error ${e.status}: ${e.message}`);
		setShowRenderErrorModal(true);
	}, [])

	// Interaction helper functions
	const handleCloseError = () => {
		setShowRenderErrorModal(false);
	};

	/* Callback function once render has completed */
	function onFinishedRender() {
		setRendering(false);
		setRequestId(null);
		setHasUpdatedProject(false);
	}

	/* Initiates and performs the render shading process */
	const renderShading = useCallback(async () => {
		setRendering(true);

		await startRequest()
			.then((newRequestId: string) => {
				// We required pass through the request id as React dispatch functions
				// don't immediately propagate their changes.
				setRequestId(newRequestId);
				return newRequestId;
			})
			.then((newRequestId: string) => {
				if (shadingStore.isDemo)
					return renderDemoShading(newRequestId, shadingStore.processingSettings);

				if (shadingStore.shadingId === null)
					throw Error("Shading doesn't contain a shading ID");

				return processCachedTiff(
					newRequestId,
					user,
					shadingStore.shadingId,
					shadingStore.processingSettings,
				);
			})
			.then(setRenderUrl)
			.catch(handleRenderFail)
			.finally(onFinishedRender);

		setRendering(false);
	}, [handleRenderFail, shadingStore.isDemo, shadingStore.processingSettings, shadingStore.shadingId, user])

	/**
	 * Function to bypass data download checks if demo data is selected
	 */
	const handleDemoLoaded = useCallback(() => {
		if (shadingStore.isDemo) {
			let user_demo_render_count;
			try {
				user_demo_render_count = parseInt(getCookie(COOKIE_USER_DEMO_RENDERS));
			}
			catch {
				user_demo_render_count = 0;
				setCookie(COOKIE_USER_DEMO_RENDERS, user_demo_render_count, 1);
			}

			if (user_demo_render_count < MAX_DEMO_RENDERS) {
				renderShading();
				setCookie(COOKIE_USER_DEMO_RENDERS, user_demo_render_count + 1, 1);
			}
			else {
				setShowMaxDemoRendersModal(true);
				if (!hasShadingRenderData) {
					shadingStore.clearShading();
				}
			}
		}
	}, [shadingStore, renderShading]);

	// Clear rendered shading on sign out
	useEffect(() => {
		if (!hasLoadedShading) {
			setRenderUrl(null);
			shadingStore.clearShading();
		}
	}, [hasLoadedShading, shadingStore])

	// Check for render progress percentage updates when a render is triggered
	useEffect(() => {
		if (!requestId || !isRendering) return;

		// Start a timer to request an update for the progress every 100ms
		setProgress(0);
		const interval = setInterval(async () => {
			try {
				const currentProgress = await getProgress(requestId);
				setProgress(currentProgress);

				// If the render is complete, delete the timer
				if (currentProgress >= 100) {
					clearInterval(interval);
				}
			} catch (error) {
				console.error("Error fetching progress:", error);
				clearInterval(interval);
			}
		}, 500);

		return () => {
			clearInterval(interval);
			setProgress(0);
		};
	}, [isRendering, requestId]);

	// Component did mount
	useEffect(() => {
		if (shadingStore.shadingId !== null) {
			renderShading();
		}
		if (shadingStore.isDemo) {
			handleDemoLoaded();
		}
	// FIXME: Ideally this is exhaustive, currently updates to the callback functions will trigger a rerender
	}, [shadingStore.isDemo, shadingStore.shadingId])

	const ProjectBarTools = () => {
		const projectBarContainer = document.getElementById('project-bar-content');
		if (!projectBarContainer) return null;

		return ReactDOM.createPortal((
			<Box
				component="section"
				display="flex"
				justifyContent="end"
				width="100%"
				paddingX={2}
			>
				<ShadingTitle title={shadingFilename} />
				<ShowGuideButton disabled={false} />
				<UserShadingButton disabled={!isAuthenticated()} />
				<CreateShadingButton
					createShadingCallback={renderShading}
					disabled={isCreateShadingDisabled}
					credits={userCredits}
				/>
				<DownloadShadingButton
					filename={shadingFilename}
					shadingId={shadingStore.shadingId}
					disabled={!canDownloadShading}
					errorOpen={showRenderErrorModal}
					setErrorOpen={setShowRenderErrorModal}
					setErrorMessage={setErrorMessage}
				/>
			</Box>
		), projectBarContainer);
	}

	const RerenderButton = () => (
		<Button
			onClick={() => (shadingStore.isDemo) ? handleDemoLoaded() : renderShading()}
			disabled={isControlsDisabled}
			className="refresh-button"
			variant="outlined"
			color="primary"
			size="large">
			<CachedIcon className="refresh-button__icon" />
			Update Shading
		</Button>
	);

	/**
	 * Element for displaying new project option dropzone
	 */
	const NavigationTiles = () => {
		const isAuthenticated = useIsAuthenticated();

		const getTiles = (): JSX.Element[] => {
			// Guest visitors can only play with the demo or see our site
			if (!isAuthenticated()) {
				return [
					<GettingStartedTile />,
					<TryDemoTile />,
				];
			}
			// General information for authenticated users
			return [
				<NewShadingTile credits={userCredits} createShadingCallback={renderShading} />,
				<ShadingsTile />,
				<CreditsTile />,
				<UserGuideTile />,
			];
		}
		const tiles = getTiles();

		return (
			<NavigationTileset rowSize={2}>{tiles}</NavigationTileset>
		);
	}

	return (
		<>
			<ProjectBarTools />
			<PageLayout>
				<Box className="background" display="flex" height="100%">
					<Box
						id="left"
						className="page-content"
						display="flex"
						flexGrow={1}
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
						minWidth="fit-content"
					>
						{isRendering &&
							<div style={{ position: 'absolute', zIndex: 10 }}>
								<LabelledProgressSpinner text="Rendering" value={progress} />
							</div>}
						{canRerenderShading &&
							<RerenderButton />}
						{hasShadingRenderData || isRendering
							? hasShadingRenderData &&
								<MapDisplay shading={shadingRender} />
							: <NavigationTiles />}
					</Box>
					<ShadingAdjustmentsPanel
						setSettings={(processingSettings: ProcessingSettings) => {
							shadingStore.setProcessingSettings(processingSettings);
							setHasUpdatedProject(true);
						}}
						disabled={isControlsDisabled}
					/>
				</Box>
			</PageLayout>
			<RenderErrorModal open={showRenderErrorModal} onCancel={handleCloseError} errorMessage={errorMessage} />
			<MaxDemoRendersModal open={showMaxDemoRendersModal} onClose={handleCloseMaxDemoRenders} />
		</>
	);
}

export default EditMapPage;
