// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Project context

import Project from '#libs/Project';
import { getUserCredits } from '#libs/apis/backend';
import { User } from '#libs/user';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import { useLocation } from 'react-router-dom';

const ProjectContext = React.createContext<Project>(new Project());

/**
 * Manages and updates credits count throughout app
 */
type CreditsContextType = {
  userCredits: number;
  updatePageCredits: () => Promise<void>;
}

const CreditsContext = React.createContext<CreditsContextType>({
  userCredits: 0,
  updatePageCredits: function (): Promise<void> {
    throw new Error("Function not implemented.");
  }
});

type CreditsProviderProps = { children: React.ReactNode };

export function CreditsProvider({ children }: CreditsProviderProps) {
  const NO_CREDITS = 0;
  const [userCredits, setUserCredits] = useState<number>(NO_CREDITS);

	const location = useLocation();
  const authData = useAuthUser();
  const user = authData() as User | null;
  const isAuthenticated = useIsAuthenticated();

  const updatePageCredits = useCallback(async () => {
    if (isAuthenticated() && user != null) {
      const credits_amount = await getUserCredits(user);
      setUserCredits(credits_amount);
    } else {
      setUserCredits(NO_CREDITS);
    }
  }, [isAuthenticated, user]);

	// Update the credit balance display on new page or new user
  useEffect(() => {
		updatePageCredits()
		.catch(console.error);
	}, [user, location.pathname, updatePageCredits]);

	return (
		<CreditsContext.Provider value={{userCredits, updatePageCredits}}>
			{children}
		</CreditsContext.Provider>
	);
}


export { CreditsContext, ProjectContext };
export type { CreditsContextType };

