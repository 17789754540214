// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Purchase credits page

import { KeyboardBackspace as KeyboardBackspaceIcon, TollTwoTone } from "@mui/icons-material";
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from 'react-router-dom';

import { CreditsContext } from "#components/Contexts";
import { NumberInput } from "#components/NumberInput";
import { ProgressSpinner } from "#components/ProgressStatus";
import { getPurchaseRatio, handleStripePurchase } from "#libs/apis/backend";
import { CreditsRatio } from "#libs/types";
import { User } from "#libs/user";
import { EDIT_MAP_PAGE } from "App";

import "#styles/pages/PaymentPage";
import PageLayout from "./PageLayout";

/**
 * Modal to indicate successful/unsuccessful purchases
 * @param open Is the modal open.
 * @param success The returned outcome of the Stripe purchase
 * @param handleClose Handler function called on closing dialog
 */
function CreditPurchaseModal({ open: isOpen, success: isPurchaseSuccessful, handleClose }: {
  open: boolean;
  success: boolean;
  handleClose: () => void;
}) {
  // Dynamic text if sucessful purchase
  const modalHeader = (isPurchaseSuccessful) ? "Purchase Successful" : "Unsuccessful Purchase";
  const modalMessage = (isPurchaseSuccessful) ? "Thank you for your payment."
                                              : "The purchase was not successful. Please try again.";
  return (
		<Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
			<DialogTitle>modalHeader</DialogTitle>
			<DialogContent sx={{ overflow: "hidden" }}>
        <div>
          <p>{modalMessage}</p>
        </div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} style={{ color: "var(--link-color)" }}>Close</Button>
			</DialogActions>
		</Dialog>
  );
}


/***
 * Error modal to indicate unable to fetch server.
 * Redirects back to the landing page.
 * @param open Is the modal open.
 */
function ServerErrorModal({ open: isOpen } : { open: boolean }) {
  const navigate = useNavigate();
  const handleClick = () => navigate(EDIT_MAP_PAGE)

  return (
		<Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
			<DialogTitle>Unable to Fetch Prices</DialogTitle>
			<DialogContent sx={{ overflow: "hidden" }}>
        <div>
          <p>Unable to fetch pricing from the server. Please try again later.</p>
        </div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClick} style={{ color: "var(--link-color)" }}>Close</Button>
			</DialogActions>
		</Dialog>
  );
}


/***
 * Confirm purchase before redirecting user to Stripe.
 * @param open Is the modal open.
 * @param handleCancel Handler function called on clicking cancel button.
 * @param handleCheckout Handler function called on proceeding to checkout.
 */
function ConfirmPurchaseModal({ open: isOpen, handleCancel, handleCheckout } : {
  open: boolean;
  handleCancel: () => void;
  handleCheckout: () => void;
}) {
  return (
		<Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill"}}>
			<DialogTitle>Purchase Credits</DialogTitle>
			<DialogContent>
        <div>
          All purchases are non refundable. Do you want to be redirected to Stripe to complete your purchase securely?
        </div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} variant="text" className="neutral-button" >Cancel</Button>
        <Button onClick={handleCheckout} variant="text" className="yes-button" >Checkout</Button>
			</DialogActions>
		</Dialog>
  );
}


/**
 * Payment page element
 * @param creditsRatio Ratio between credits provided and cost
 * @param showConfirmationModal Is confirmation of purchase modal displayed  #FIXME: Ideally, this should be at top level
 * @param setShowConfirmationModal React dispatch to toggle confirmation modal state.  #FIXME: Ideally, this should be at top level
 */
function Payment({ creditsRatio, showConfirmationModal, setShowConfirmationModal }: {
  creditsRatio: CreditsRatio;
  showConfirmationModal: boolean;
  setShowConfirmationModal: (b: boolean) => void;
}) {
  // Constants
  const MIN_CREDITS = creditsRatio.credits;  // Minimum credits required for purchase
  const DEFAULT_PURCHASE_CREDIT_AMOUNT = 1;  // Default credit purchase amount
  const MAX_CREDITS = 20; // Maximum credits allowed for purchase

  // State
  const [creditAmount, setCreditAmount] = useState(DEFAULT_PURCHASE_CREDIT_AMOUNT);
  const [selectedOption, setSelectedOption] = useState<string>(creditsRatio.currency);

  // Converted prioce amount
  const unitsAmount = creditAmount / creditsRatio.credits;  // Purchasing quantity on the server side
  const convertedPrice = unitsAmount * creditsRatio.cost / 100;

  // Get user auth key
  const auth = useAuthUser();
  const user = auth() as User | null;

  const handleCancel = () => setShowConfirmationModal(false);
  const handleCreditAmountChange = (e: any, value: number | null) => {
    if (value !== null) {
      setCreditAmount(value);
    }
  }
  // Async function to handle credit purchase
  const handleCheckout = async () => {
    if (user == null)
      throw new Error("User is not authenticated");

    await handleStripePurchase(unitsAmount, selectedOption, user);
  }

  return (
    <>
      {/* Purchase new credits */}
      <form onSubmit={(e) => e.preventDefault()} style={{display: 'grid', gridTemplateRows: '0px 1fr', gridTemplateColumns: '1fr 1fr'}}>
        {/* <img src={logo} alt="Eduard icon"className="eduard-logo" style={{gridColumn: 1, gridRow: 1, justifySelf: 'center'}}/> */}
        <div className="conversionBox" style={{ gridRow: 2, gridColumn: 1, justifyContent: 'center', padding: 5}}>
          <span style={{alignContent: "center"}}>
            <TollTwoTone style={{margin: 5}}/>
            Credits
          </span>
          <NumberInput  // Lacks styling of standard text field
            value={creditAmount}
            min={MIN_CREDITS}
            max={MAX_CREDITS}
            step={creditsRatio.credits}
            onChange={handleCreditAmountChange}
            style={{ marginLeft: '15px' }}
          />
        </div>
        <div style={{gridRow: 2, gridColumn: 2}}>
          <div className="conversionBox">
            <span>{`$ ${convertedPrice.toFixed(2)}`}</span>
            <div>{creditsRatio.currency.toUpperCase()}</div>
          </div>
        </div>
      </form>
      <ConfirmPurchaseModal open={showConfirmationModal} handleCancel={handleCancel} handleCheckout={handleCheckout}/>
    </>
  );
}


/**
 * Payments page used to purchase Eduard credits
 * Redirects to Stripe payment
 */
function PaymentPage(): JSX.Element {
  // Contexts
  const { userCredits, updatePageCredits } = React.useContext(CreditsContext);

  // Modal states
  const [isPurchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const [isSuccessfulPurchase, setSuccessfulPurchase] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // Fetch credits
  const [isRequesting, setRequesting] = React.useState(true);
  const [creditsRatio, setCreditsRatio] = React.useState<CreditsRatio>();
  const isServerErrorModalOpen = !isRequesting && (creditsRatio == undefined);

  // Navigation
  const navigate = useNavigate();
  const navigateToEditPage = () => navigate(EDIT_MAP_PAGE);

  // Modal handler functions
  const handleClosePurchaseModal = () => navigate(EDIT_MAP_PAGE);
  const handleOpenConfirmation = () => setShowConfirmationModal(true);

  // Display purchase success or failure message on page load
  function handleStripeRedirect() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('success')) {
      updatePageCredits();
      setPurchaseModalOpen(true);
      setSuccessfulPurchase(true);
    }
    else if (urlParams.get('cancel')) {
      setPurchaseModalOpen(true);
      setSuccessfulPurchase(false);
    }
  }

  // Fetch Credits pricing from the backend server
  async function fetchPricingFromServer() {
    try {
      setRequesting(true);
      const newCreditsRatio = await getPurchaseRatio();
      setCreditsRatio(newCreditsRatio);
    }
    catch {
      console.error("Unable to fetch prices from the server.");
    }
    finally {
      setRequesting(false);
    }
  }

  // Hook to fetch dialog from server.
  useEffect(() => {
    handleStripeRedirect();
    fetchPricingFromServer();
  }, []);

  return <>
    <PageLayout>
      <div className="page-layout">
        <Container maxWidth="sm" style={{paddingTop: 10}}>
          <Button
            onClick={navigateToEditPage}
            children="Back"
            startIcon={<KeyboardBackspaceIcon />}
            sx={{ 'justify-content': "left" }}
          />
          {/* Title and current credit balance */}
          <h1>Eduard Credits</h1>
          <hr />
          <p>
            <b>{`Current credit balance: `}</b>
            <TollTwoTone sx={{marginLeft: 5, marginRight: 1}}/>
            {userCredits}
          </p>
          <p>
            One Eduard credit allows you to load a single elevation model. You can adjust rendering settings and download the shaded image as often as you like, but the elevation data itself cannot be changed.
          </p>
          <p>Enter the number of credits you want to purchase.</p>
          <div style={{display: 'flex', justifyContent: 'center', margin: '20px'}}>
            {!isRequesting && creditsRatio
              ? <Payment
                creditsRatio={creditsRatio}
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
              />
              : <ProgressSpinner text="Fetching prices"/> }
          </div>
          <div>
            <p>
              All purchases are non refundable.
              Click "Checkout" to be securely redirected to Stripe, our trusted payment provider, to complete the transaction.
            </p>
            <button type="button" disabled={isRequesting} onClick={handleOpenConfirmation} className="pay-button">Checkout</button>
          </div>
        </Container>
      </div>
    </PageLayout>
    <CreditPurchaseModal open={isPurchaseModalOpen} success={isSuccessfulPurchase} handleClose={handleClosePurchaseModal} />
    <ServerErrorModal open={isServerErrorModalOpen} />
  </>
}

export default PaymentPage;
