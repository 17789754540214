import { ReactElement} from "react";
import L from "leaflet"
import { useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";


export enum LeafletMapPosition {
  BOTTOM_LEFT = "leaflet-bottom leaflet-left",
  BOTTOM_RIGHT = "leaflet-bottom leaflet-right",
  TOP_LEFT = "leaflet-top leaflet-left",
  TOP_RIGHT = "leaflet-top leaflet-right",
}

export default function MapToolbar({ children, position }: {
	children: ReactElement[];
	position?: LeafletMapPosition;
}) {
	const positionClass = position || LeafletMapPosition.TOP_RIGHT;

	return (
		<div className={positionClass}>
			<div className="leaflet-bar">
				{children}
			</div>
		</div>
	)
}
