"use client";

import { CreditsContext, ProjectContext } from "#components/Contexts";
import DEMSelection from "#components/map-selector/DEMSelection";
import LoadingAreaBackdrop from "#components/LoadingAreaBackdrop";
import { BaseModal } from "#components/modals/modals";
import Project from "#libs/Project";
import { createShading } from "#libs/apis/backend";
import { DEFAULT_SHADING_NAME } from "#libs/constants";
import { User } from "#libs/user";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
	Box,
	Button,
	Tab,
	TextField
} from "@mui/material";
import React, { createContext, Dispatch, useContext, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { OpenTopographyErrorModal } from "#components/modals/OpenTopographyErrorModal";
import { useShadingStore } from "#components/store";
import { ElevationData } from "#libs/types";


function DEMGridTabs() {
	const SELECT_AREA_TAB = 0;
	const UPLOAD_GRID_TAB = 1;

	const [tab, setTab] = useState<number>(SELECT_AREA_TAB);

	const handleTabChange = (_event: React.SyntheticEvent, newTab: number) => {
		setTab(newTab);
	};

	return (
		<TabContext value={tab}>
			<Box
				display="flex"
				flexDirection="column"
				width="inherit"
				height="inherit"
			>
				<TabList
					onChange={handleTabChange}
					orientation="horizontal"  // FIXME: Should check the device is mobile (use landscape if so)
					sx={{
						borderBottom: 1,
						borderColor: 'divider',
						width: "100%",
						marginBottom: "1em",
					}}>
					<Tab label="Select Area" value={SELECT_AREA_TAB} />
					<Tab label="Upload Grid" value={UPLOAD_GRID_TAB} disabled />
				</TabList>
				<TabPanel
					value={SELECT_AREA_TAB}
					sx={{
						height: "inherit",
						width: "auto",
						flexGrow: 1,
						padding: 0,
					}}>
					<DEMSelection />
				</TabPanel>
			</Box>
		</TabContext>
	);
}


export interface ValidElevationDataContextProps {
	valid: boolean;
	setValid: Dispatch<boolean>;
	elevationData: ElevationData | null
	setElevationData: Dispatch<ElevationData | null>;
}

export const ValidElevationDataContext = createContext<ValidElevationDataContextProps>({
	valid: false,
	setValid: () => {},
	elevationData: null,
	setElevationData: () => {},
});

/**
 * Create new shading dialog. Gets input name of the newly created shading.
 * @param { isOpen, onConfirm, onCancel }
 */
export default function CreateShadingModal({ open, onConfirm: confirmCallback, onCancel }: {
	open: boolean;
	onConfirm: (arg0: string) => void;
	onCancel: () => void;
}) {
	// App state
	const auth = useAuthUser();
	const user = auth() as User | null;
	const { updatePageCredits } = useContext(CreditsContext);
	const project = useContext(ProjectContext);
	const shadingStore = useShadingStore.getState();

	// New shading data
	const [shadingName, setShadingName] = useState<string>(DEFAULT_SHADING_NAME);
	const [elevationData, setElevationData] = useState<ElevationData | null>(null);

	// Modal state
	const [isValidDEM, setIsValidDem] = useState<boolean>(false);
	const [isSavingShading, setSavingShading] = useState<boolean>(false);
	const [showApiKeyModal, setShowApiKeyModal] = useState<boolean>(false);
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [apiErrorText, setApiErrorText] = useState<string>("");

	// const [bounds, setBounds] = useState<L.LatLngBounds>(cachedBounds.bounds);
	// const [elevationModel, setElevationModel] = useState<ElevationModel>(DEFAULT_ELEVATION_MODEL);
	// const [projection, setProjection] = useState<Projection>(DEFAULT_PROJECTION);

	const handleShadingNameChange = (e: React.FocusEvent<HTMLInputElement>) => {
		const newShadingName = (e.target.value.length > 0) ? e.target.value : DEFAULT_SHADING_NAME;
		setShadingName(newShadingName);
	}

	const handleConfirm = async () => {
		if (user === null)
			throw new Error("User must be authenticated to create a new shading");
		if (elevationData === null)
			throw new Error("No elevation data bounds provided to to create a new shading");

		setSavingShading(true);
		try {
			// Save map data
			const shadingId = await createShading(user, shadingName, elevationData);
			shadingStore.loadShading(shadingId, shadingName, elevationData.bounds);

			updatePageCredits();
			confirmCallback(shadingName);
		}
		catch (error: unknown) {
			setApiErrorText("Failed to create new project, please try again later");
			setShowApiKeyModal(true);
		}
		setSavingShading(false);
	}

	const tabPadding = "1.5em"

	const dialogActions = <>
		<Button onClick={onCancel} className="neutral-button">Cancel</Button>
		{isValidDEM
			? <Button onClick={handleConfirm} disabled={isSavingShading} className="yes-button">Create</Button>
			: <Button disabled className="no-button" >Invalid Area</Button>
		}
	</>

	return (
		<>
			<BaseModal open={open} heading="New Shading" maxWidth="md" dialogActions={dialogActions}>
				<Box component="form" display="flex" flexDirection="column" gap="0.25rem">
					<Box display="flex" paddingInline={tabPadding}>
						<Box component="span" width="8em" alignSelf="center">Name:</Box>
						<TextField
							label="Shading name"
							defaultValue={shadingName}
							onBlur={handleShadingNameChange}
							variant="outlined"
							required
							size="small"
							fullWidth
							sx={{ flexGrow: 1 }}
						/>
					</Box>
					<Box height={500}>
						<ValidElevationDataContext.Provider
							value={{
								valid: isValidDEM,
								setValid: setIsValidDem,
								elevationData,
								setElevationData,
							}}
						>
							<DEMGridTabs />
						</ValidElevationDataContext.Provider>
					</Box>
					<Box
						display="flex"
						justifyContent="end"
						paddingInline={tabPadding}
						marginTop="0.5em"
					>
						Creating a new shading will cost one Eduard credit.
					</Box>
				</Box>
			</BaseModal>
			<LoadingAreaBackdrop isActive={isSavingShading} />
			<OpenTopographyErrorModal
				isOpen={showErrorModal}
				errorMessage={apiErrorText}
				onCancel={() => {
					setShowErrorModal(false);
				}}
			/>
		</>
	);
}
