// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Edit page render settings panel
import CircularSlider from "@fseehawer/react-circular-slider";
import {
	LensOutlined as LensOutlinedIcon,
	LensTwoTone as LensTwoToneIcon,
	LightModeRounded as LightModeRoundedIcon,
	Link as LinkIcon
} from "@mui/icons-material";
import {
	Button,
	Icon,
	InputAdornment,
	Slider
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Flags } from 'react-feature-flags';

import { ProjectContext } from "#components/Contexts";
import Project from "#libs/Project";
import createRipple from "#libs/styling/ripple";
import { ProcessingSettings } from "#libs/types";
import AttributeContainer, { AttributeInput } from "./AttributeContainer";
// NN model icons
import alpineTerrain from "#assets/Eduard Icons/Range sliders/alpine 1x.png";
import flatTerrain from "#assets/Eduard Icons/Range sliders/flat 1x.png";
import largeScale from "#assets/Eduard Icons/styles/large-scale-2x.png";
import mediumScale from "#assets/Eduard Icons/styles/medium-scale-2x.png";
import smallScale from "#assets/Eduard Icons/styles/small-scale-2x.png";
import { NumberInput } from "#components/NumberInput";
import "#styles/components/MapProperties";
import "#styles/pages/EditMapPage";

/**
 * Neural network model select buttons
 * @param {
 *   setParams: callback,
 * }
 */
function NeuralNetworkControl({
  setParams: callback,
  disabled: isDisabled = false,
}: {
  setParams: (f: number) => void;
  disabled?: boolean;
}) {
  const allModels = {
    large: largeScale,
    medium: mediumScale,
    small: smallScale,
  };
  const project = useContext(ProjectContext);
  const [model, setModel] = useState(project.settings.modelNo);
  React.useEffect(() => {
    callback(model)
    project.settings.modelNo = model;
  }, [model]);

  // Create buttons
  const buttons = Object.entries(allModels).map(([name, icon], index) => {
    const modelNo = index + 1;
    const isSelected = modelNo === model;
    return (
      <Button
        key={name}
        onClick={() => setModel(modelNo)}
        disabled={isDisabled}
        className="model-button"
        color={isSelected ? "primary" : "secondary"}
        variant="outlined"
        sx={{
          border: `${isSelected ? 2 : 1}px solid`,
          height: 60,
          width: 60,
        }}
      >
        <img style={{width: 55, height: 55}}src={icon} alt={name} />
      </Button>
    );
  });

  return (
    <AttributeContainer
      description="The style and level of detail of the shading."
      noDivider
    >
      <span style={{
        translate: "0px -20px",
        marginRight: "16px",
        height: "50px",
        display: "flex",
        justifyContent: "space-around",
      }}>{buttons}</span>
    </AttributeContainer>
  );
}

/**
 * Illumination angle control circular slider
 * @param {
 *   setParams: callback,
 * }
 */
function IlluminationControl({ setParams: callback, disabled: isDisabled = false }: {
  setParams: (f: number) => void;
  disabled?: boolean;
}) {
	const CLOCKWISE = 1;
  const [MIN_VALUE, MAX_VALUE] = [-179, 180];
	const DEFAULT_VALUE = 0;
	// The angle range for light rotation range is (-180, 180]
	const DIAL_DATA = Array.from({length: 360}, (x, i) => i > 180 ? i - 360 : i);

  const project = useContext<Project>(ProjectContext);
  const [lightRotation, setLightRotation] = useState<number>(project.settings.lightRotation);

  // Track colours
  const trackActiveColor = !isDisabled ? "var(--primary-color)" : "var(--secondary-color)";
  const trackBaseColor = !isDisabled ? "rgba(var(--primary-color-raw), 0.5)" : "rgba(var(--secondary-color-raw), 0.5)";

  // Value should circle around to min/max at boundaries
  const handleInputBoxChange = (event: any, value: number | null) => {
    const inputVal = (value !== null) ? value : lightRotation;
    if (inputVal > MAX_VALUE) {
      setLightRotation(MIN_VALUE);
    } else if (inputVal < MIN_VALUE) {
      setLightRotation(MAX_VALUE);
    } else {
      setLightRotation(inputVal);
    }
  };
  const resetDial = () => {if (!isDisabled) setLightRotation(DEFAULT_VALUE)};

  useEffect(() => {
    callback(lightRotation);
    project.settings.lightRotation = lightRotation;
  }, [lightRotation]);

  return (
    <AttributeContainer
      paramName="Illumination"
      description="The horizontal light direction."
    >
      <div style={{ display: "grid", gridTemplateColumns: "25% 1fr 80px" }}>
        <div className="circle-slider" style={{ gridColumn: 2}}>
          <LensTwoToneIcon
            onClick={resetDial}
            className="circle-slider__center-icon clickable"
            fontSize="small"
            aria-disabled={isDisabled}
          />
          {/* Circular slider: https://github.com/fseehawer/react-circular-slider */}
          <CircularSlider
						data={DIAL_DATA}
            dataIndex={lightRotation}
            onChange={setLightRotation}
            knobDraggable={!isDisabled}
            width={65}
            trackSize={4}
            progressSize={6}
            knobSize={25}
            knobPosition={135}
            direction={CLOCKWISE}
            hideLabelValue
            knobColor={trackActiveColor}
            trackColor={trackBaseColor}
            progressColorFrom={trackActiveColor}
            progressColorTo={trackActiveColor}
          >
            <LensOutlinedIcon className="circle-slider__knob-icon" />
            <LightModeRoundedIcon
              className="circle-slider__knob-icon"
              x="5px"
              y="5px"
              width="15px"
              height="15px"
            />
          </CircularSlider>
        </div>
        <div className="input-box" style={{ gridColumn: 3}}>
          <NumberInput
            value={lightRotation}
            min={MIN_VALUE - 1}
            max={MAX_VALUE + 1}
            step={1}
            onChange={(e, val) => handleInputBoxChange(e, val)}
            disabled={isDisabled}
            endAdornment={
              <InputAdornment
                children="°"
                position="end"
                disableTypography
                style={{
                  color: "var(--primary-text)",
                  position: 'relative',   // Placement of adorments not working with grid
                  top: '-17px',           // Current workaround is with this manual adjustment :\
                  right: '-70%',
                }}
              />
            }
          />
        </div>
      </div>
    </AttributeContainer>
  );
}

interface GeneralizationProps {
  setParams: (gen: number, genDetails: number) => void;
  disabled?: boolean;
}

/**
 * Generalization (Macro & Micro) control sliders
 * @param { setParams: callback }
 */
function GeneralizationControl({ setParams: callback, disabled: isDisabled=false}: GeneralizationProps) {
  const project = useContext(ProjectContext);

  const [macro, setMacro] = useState(project.settings.generalization);
  const [micro, setMicro] = useState(project.settings.generalizationDetails);
  const [isBound, setBound] = useState(true);

  // Handle slider changes
  const handleMacroChange = (newMacro: number) => {
    if (isBound) setMicro(newMacro);
    setMacro(newMacro);
  };
  const handleMicroChange = (newMicro: number) => {
    if (isBound) setMacro(newMicro);
    setMicro(newMicro);
  };

  React.useEffect(() => {
    callback(macro, micro)
    project.settings.generalization = macro;
    project.settings.generalizationDetails = micro;
  }, [macro, micro]);

  return (
      <AttributeContainer
        paramName="Generalization"
        description="Increase the  Macro  value to simplify regional macro landforms. Increase the  Micro  value to remove local micro details. Click on the chain icon to adjust the Macro and Micro values individually."
      >
        <div className="d-flex flex-column">
          {/* Macro slider */}
          <AttributeInput
            label="Macro"
            value={macro}
            callback={handleMacroChange}
            disabled={isDisabled}
          />
          {/* Give the option to bind sliders together (on by default) */}
          <LinkInputsButton isBound={isBound} callback={setBound} disabled={isDisabled}/>
          {/* Micro slider */}
          <AttributeInput
            label="Micro"
            value={micro}
            callback={handleMicroChange}
            disabled={isDisabled}
          />
        </div>
      </AttributeContainer>
  );
}

/**
 * Aerial perspective control slider
 * @param {
 *   setParams: callback,
 * }
 */
function AerialPerspectiveControl({
  setParams: callback,
  disabled: isDisabled = false,
}: {
  setParams: (f: number) => void;
  disabled?: boolean;
}) {
  const project = useContext(ProjectContext);
  const [aerial, setAerial] = useState(project.settings.aerialPerspective);

  // Handle value change
  const handleChange = (newAerial: number) => setAerial(newAerial);
  React.useEffect(() => {
    callback(aerial);
    project.settings.aerialPerspective = aerial;
  }, [aerial]);

  return (
    <AttributeContainer
      paramName="Aerial Perspective"
      description="Aerial perspective simulation to emphasize high elevations."
    >
      <AttributeInput callback={handleChange} value={aerial} disabled={isDisabled}/>
    </AttributeContainer>
  );
}

interface TerrainTypeProps {
  setParams: (min: number, max: number) => void;
  disabled?: boolean;
}

/**
 * Terrain type control min-max slider
 * @param {
 *   setParams: callback,
 * }
 */
function TerrainTypeControl({setParams: callback, disabled: isDisabled=false}: TerrainTypeProps) {
  const [MIN_VALUE, MAX_VALUE] = [0, 100];
  const MIN_DIST = 1;
  const project = useContext(ProjectContext);

  const [value, setValue] = useState<number[]>([
    project.settings.elevationRangeMin,
    project.settings.elevationRangeMax,
  ]);
  const [low, high] = value;

  // Handle value change
  const handleChange = (
    event: Event | null,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < MIN_DIST) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], MAX_VALUE - MIN_DIST);
        setValue([clamped, clamped + MIN_DIST]);
      } else {
        const clamped = Math.max(newValue[1], MIN_DIST);
        setValue([clamped - MIN_DIST, clamped]);
      }
    } else {
      setValue(newValue as number[]);
    }
  };

  React.useEffect(() => {
    callback(low, high);
    project.settings.elevationRangeMin = low;
    project.settings.elevationRangeMax = high;
  }, [low, high]);

  return (
    <AttributeContainer
      paramName="Terrain Type"
      description="Adjust the elevation range: Increase the upper value for mountainous terrain and decrease it for flat areas. For most terrains, the lower value can remain at 0."
    >
      <div className="terrain-type__root" >
        <NumberInput  // Lacks styling of standard text field
          value={low}
          min={MIN_VALUE}
          max={MAX_VALUE}
          step={1}
          onChange={(e: any, value: any) => handleChange(null, [parseInt(value), high], 0)}
          disabled={isDisabled}
          style={{ gridRow: 1, gridColumn: 1 }}
        />
        <Slider
          value={value}
          onChange={handleChange}
          disabled={isDisabled}
          className="terrain-type__slider"
          style={{ gridRow: 1, gridColumn: 2 }}
        />
        <NumberInput  // Lacks styling of standard text field
          value={high}
          min={MIN_VALUE}
          max={MAX_VALUE}
          step={1}
          onChange={(e: any, value: any) => handleChange(null, [low, parseInt(value)], 1)}
          disabled={isDisabled}
          style={{ gridRow: 1, gridColumn: 3 }}
        />
        <div
          className="d-flex justify-content-between"
          style={{ gridRow: 2, gridColumn: 2 }}
        >
          <Icon
            className="terrain-type__terrain-icon clickable"
            aria-disabled={isDisabled}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              if (!isDisabled) {
                createRipple(e, 10);
                setValue([MIN_VALUE, high]);
              }
            }}
          >
            <img src={flatTerrain} alt="flat" aria-disabled={isDisabled} />
          </Icon>
          <Icon
            className="terrain-type__terrain-icon clickable"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              if (!isDisabled) {
                createRipple(e, 100);
                setValue([low, MAX_VALUE]);
              }
            }}
            aria-disabled={isDisabled}
          >
            <img src={alpineTerrain} alt="alpine" aria-disabled={isDisabled} />
          </Icon>
        </div>
      </div>
    </AttributeContainer>
  );
}

interface FlatAreaProps {
  setParams: (amount: number, size: number) => void;
  disabled?: boolean;
}

/**
 * Flat area detail effect control sliders
 * @param { setParams }
 */
function FlatAreaDetailsControl({setParams: callback, disabled: isDisabled=false}: FlatAreaProps) {
  const project = useContext(ProjectContext);

  const [isBound, setBound] = useState(false);
  const [flatArea, setFlatArea] = useState<number[]>([
    project.settings.flatAreaAmount,
    project.settings.flatAreaSize,
  ]);

  const [amount, size] = flatArea;
  const isSizeDisabled = isDisabled || amount === 0;

  // Handle value changes
  const handleAmountChange = (newVal: number) => {
    isBound ? setFlatArea([newVal, newVal]) : setFlatArea([newVal, size]);
  };
  const handleSizeChange = (newVal: number) => {
    isBound ? setFlatArea([newVal, newVal]) : setFlatArea([amount, newVal]);
  };

  React.useEffect(() => {
    callback(amount, size);
    project.settings.flatAreaAmount = amount;
    project.settings.flatAreaSize = size;
  }, [amount, size]);

  return (
    <>
      <AttributeContainer
        paramName="Flat Area Details"
        description={
          <>
            <div>Amount: Emphasize details in flat areas.</div>
            <div>
              Size: Adjust the size of emphasized details in flat areas.
            </div>
          </>
        }
      >
        <div className="d-flex flex-column">
          {/* Amount slider */}
          <AttributeInput
            label="Amount"
            value={amount}
            callback={handleAmountChange}
            disabled={isDisabled}
          />
          <LinkInputsButton isBound={isBound} callback={setBound} disabled={isDisabled}/>

          {/* Size slider */}
          <AttributeInput
            label="Size"
            value={size}
            callback={handleSizeChange}
            disabled={isSizeDisabled}
          />
        </div>
      </AttributeContainer>
    </>
  );
}

interface LinkInputsButtonProps {
  isBound: boolean;
  callback: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

/**
 * Toggle linked sliders button
 * @param { isBound, callback }
 */
function LinkInputsButton({ isBound, callback, disabled: isDisabled=false}: LinkInputsButtonProps) {
  const toggleBound = () => {if (!isDisabled) callback(!isBound)};

  return (
    <LinkIcon
      className={`link-button clickable rotate-link${!isBound ? "-reverse" : ""}`}
      onClick={toggleBound}
      aria-disabled={isDisabled}
    />
  );
}

/**
 * Contrast control slider
 * @param {
 *   setParams: callback,
 * }
 */
function ContrastControl({ setParams: callback, disabled: isDisabled=false }: {
  setParams: (f: number) => void;
  disabled?: boolean;
}) {
  const project = useContext(ProjectContext);
  const [contrast, setContrast] = useState(project.settings.slopeDarkness);

  const handleChange = (value: number) => setContrast(value);

  // Handle value change
  React.useEffect(() => {
    callback(contrast);
    project.settings.slopeDarkness = contrast;
  }, [contrast]);

  return (
    <AttributeContainer
      paramName="Contrast"
      description="Darkness of shaded slopes at high elevations."
    >
      <AttributeInput callback={handleChange} value={contrast} disabled={isDisabled}/>
    </AttributeContainer>
  );
}


/**
 * Render settings panel component
 * @param { callback }
 */
function MapProperties({ callback, disabled=false }: { callback: any, disabled: boolean }) {
  const project = useContext(ProjectContext);
  const [mapParams, setMapParams] = useState<ProcessingSettings>(
    project.settings
  );

  /**
   * Merge setting values from all sliders
   * @param params
   */
  const mergeParams = (params: object) =>
    setMapParams((prevState) => {
      return {...prevState, ...params };
    });

  // Handle change of settings
  React.useEffect(() => {
    project.settings = mapParams;
    callback(mapParams);
  }, [mapParams]);

  return (
    <>
      {/* Display all setting sliders */}
      <NeuralNetworkControl
        setParams={(modelNo) => mergeParams({ modelNo: modelNo })}
        disabled={disabled}
      />
      <IlluminationControl
        setParams={(angle: number) => mergeParams({ lightRotation: angle })}
        disabled={disabled}
      />
      <GeneralizationControl
        setParams={(macro: number, micro: number) =>
          mergeParams({
            generalization: macro,
            generalizationDetails: micro,
          })
        }
        disabled={disabled}
      />
      <AerialPerspectiveControl
        setParams={(aerial: number) =>
          mergeParams({ aerialPerspective: aerial })
        }
        disabled={disabled}
      />
      <ContrastControl
        setParams={(contrast: number) =>
          mergeParams({ slopeDarkness: contrast })
        }
        disabled={disabled}
      />
      <TerrainTypeControl
        setParams={(min: number, max: number) =>
          mergeParams({
            elevationRangeMin: min,
            elevationRangeMax: max,
          })
        }
        disabled={disabled}
      />
      <Flags authorizedFlags={['flatAreaDetails']}>
        <FlatAreaDetailsControl
          setParams={(amount: number, size: number) =>
            mergeParams({
              flatAreaAmount: amount,
              flatAreaSize: size,
            })
          }
          disabled={disabled}
        />
      </Flags>
    </>
  );
}

export default MapProperties;
