import { Box, Link } from "@mui/material";
import { INFO_PAGE, PRIVACY_POLICY_PAGE } from "App";


/**
 * Attribution and contact info for Monash & Eduard Cloud policies
 */
function AttributionNotice() {
  return (
    <Box
			component="span"
			position="absolute"
			bottom={0}
			left={5}
			fontSize={12}
			flexWrap="nowrap"
		>
      {"© 2025 Monash University"}
			{" | "}
      <Link href={PRIVACY_POLICY_PAGE} target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
      {" | "}
      <Link href={INFO_PAGE} target="_blank" rel="noopener noreferrer">About & Contact</Link>
    </Box>
  );
}

export default AttributionNotice;
