import logo from "#assets/logo.png";
import MapSelector, { DEFAULT_ZOOM } from "#components/map-selector/MapSelector";
import DEMDropdown from "#components/map-properties/DEMDropdown";
import ProjectionDropdown from "#components/map-properties/ProjectionDropdown";
import { ValidElevationDataContextProps, ValidElevationDataContext } from "#components/modals/CreateShadingModal";
import { BaseModal, } from "#components/modals/modals";
import {
	getUserApiKey,
	setUserApiKey,
} from "#libs/apis/backend";
import {
	DEFAULT_ELEVATION_MODEL,
	DEFAULT_PROJECTION,
	ElevationModel,
	Projection
} from "#libs/Project";
import { COOKIE_PREVIOUS_AREA_SELECTION } from "#libs/sessionStorageKeys";
import { User } from "#libs/user";
import { getCookie, KeyError, setCookie } from "#libs/utils";
import "#styles/pages/DownloadMap";
import { Box, Link, TextField } from "@mui/material";
import { EDIT_MAP_PAGE } from "App";
import L from 'leaflet';
import React, { ChangeEvent, useCallback, useContext, useState, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { OpenTopographyElevationData } from "#libs/types";

const DEFAULT_BOUNDS = new L.LatLngBounds([[46.0, 8.6], [46.4, 9.2]]);  // Eduard starting position

/**
 * Check non-empty api key is set.
 * @param apiKey The provided api key to test.
 * @returns Truthy value of valid key.
 */
function isValidApiKey(apiKey?: string): boolean {
	return apiKey !== "" && apiKey != undefined;
}


/***
 * Dialog box for requesting OpenTopography API Key.
 */
function ApiKeyModal({ open, setOpen, updateApiKey }: {
	open: boolean,
	setOpen: React.Dispatch<boolean>
	updateApiKey: () => void
}) {
	const navigate = useNavigate();
	const auth = useAuthUser();

	const user = auth() as User | null;
	const [apiKey, setApiKey] = useState<string>("");

	const handleClose = () => setOpen(false);
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => setApiKey(e.target.value);
	// Close the dialogue box and return to previous page
	const handleCancel = () => {
		handleClose();
		navigate(EDIT_MAP_PAGE);
	};

	// Update the API key
	const handleSubmit = async () => {
		if (user == null)
			throw new Error("User is not authenticated");

		await setUserApiKey(user, apiKey)
			.then(updateApiKey)
			.then(handleClose)
			.catch(console.error);
	};

	return (
		<BaseModal
			open={open}
			heading="OpenTopography API Key"
			neutralButton={{ title: "Cancel", onClick: handleCancel }}
			yesButton={{ title: "Submit", onClick: handleSubmit }}
			maxWidth="sm" >
			<div>
				<span>An API Key is needed to download grids from OpenTopography.</span>
				<br />
				<Link href="https://opentopography.org" target="_blank">
					Request an API key from OpenTopography.org.
				</Link>
			</div>
			<TextField
				autoFocus
				className="api-key-modal__input-label"
				fullWidth
				id="apiKey"
				label="OpenTopography API Key"
				margin="dense"
				onChange={handleChange}
				type="text"
				variant="standard"
			/>
		</BaseModal>
	);
}


function ConfirmAreaModal({ open, onConfirm, onCancel }: {
	open: boolean
	onConfirm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) {
	return (
		<BaseModal
			open={open}
			heading="Confirm Area"
			yesButton={{ title: "Confirm", onClick: onConfirm }}
			neutralButton={{ title: "Cancel", onClick: onCancel }}
			maxWidth="xs" >
			<div style={{ paddingInline: 30 }}>
				<p>
					The selected area will be final and cannot be changed for this shading.
					Are you sure you want to select this area?
				</p>
			</div>
		</BaseModal>
	);
}


/**
 * OpenTopography error reponse modal
 * @param { isOpen, errorMessage, httpCode, onCancel }
 */
function OpenTopographyErrorModal({ isOpen, errorMessage, onCancel }: {
	isOpen: boolean;
	errorMessage: string | null;
	onCancel: () => void;
}) {
	return (
		<BaseModal
			open={isOpen}
			heading="OpenTopography Error"
			noButton={{ title: "OK", onClick: onCancel }} >
			<div style={{ paddingInline: 30, display: "grid" }}>
				<img className="eduard-logo" style={{ justifySelf: "center", marginBottom: 10 }} src={logo} alt="Eduard icon" />
				<p>{errorMessage}</p>
			</div>
		</BaseModal>
	);
}

interface CachedBounds {
	zoom: number,
	bounds: L.LatLngBounds,  // stored as L.LatLngBounds.toBBoxString()
}

function getBoundsOnPageLoad(): CachedBounds {
	try {
		// Unpack the cached L.LatLngBounds string into respective coordinates
		const cookieData = getCookie(COOKIE_PREVIOUS_AREA_SELECTION);
		const cachedBoundsData = JSON.parse(cookieData)
		const [sw_lng, sw_lat, ne_lng, ne_lat] = cachedBoundsData.bounds.split(',').map(Number);

		return {
			zoom: cachedBoundsData.zoom,
			bounds: new L.LatLngBounds([sw_lat, sw_lng], [ne_lat, ne_lng]),
		};
	}
	catch (e) {
		// No cookie found
		if (e instanceof KeyError) {
			return {
				zoom: DEFAULT_ZOOM,
				bounds: DEFAULT_BOUNDS,
			};
		}
		// Erroneous deserialisation
		else {
			throw e;
		}
	}
}

function cacheBoundsAsCookie(bounds: L.LatLngBounds, zoom: number) {
	const COOKIE_DURATION = 0.02;  // Approximately ~30 minutes (in days)
	setCookie(COOKIE_PREVIOUS_AREA_SELECTION, {
		zoom: zoom,
		bounds: bounds.toBBoxString(),
	}, COOKIE_DURATION);
}

// Footer element container for DEMSElection
function Footer({ children }: { children: React.ReactNode }) {
	return (
		<Box width="100%">
			<div className="panel glass--dark">
				<Box
					display="flex"
					gap="2rem"
					justifyContent="space-between"
					alignItems="center"
					paddingInline="1em"
				>
					{children}
				</Box>
			</div>
		</Box>
	);
}

/**
 * Download map page
 * TODO: Clean this mess up
 */
function DEMSelection(): JSX.Element {
	// Current user data
	const auth = useAuthUser();
	const user = auth() as User | null;
	const [apiKey, setApiKey] = useState<string>("");

	// Cached selection area from last session
	const cachedBounds: CachedBounds = getBoundsOnPageLoad();
	const initialZoom = cachedBounds.zoom || DEFAULT_ZOOM;

	// Project settings
	const [bounds, setBounds] = useState<L.LatLngBounds>(cachedBounds.bounds);
	const [elevationModel, setElevationModel] = useState<ElevationModel>(DEFAULT_ELEVATION_MODEL);
	const [projection, setProjection] = useState<Projection>(DEFAULT_PROJECTION);

	// Page state
	// const validBoundsSelection = PostGIS_ST_Area(bounds) < MAX_AREA_SIZE;
	// const isDownloadButtonActive = isValidApiKey(apiKey) && validBoundsSelection;

	// Modals
	const [showApiKeyModal, setShowApiKeyModal] = useState<boolean>(false);

	const { valid, setValid, setElevationData } = useContext<ValidElevationDataContextProps>(ValidElevationDataContext);
	setValid(elevationModel.isValidBounds(bounds));  // Need to check DEM types

	const updatePageApiKey = useCallback(async () => {
		if (user == null)
			throw new Error("User is not authenticated");
		// Request backend api for key or try local storage
		const apiKey = await getUserApiKey(user);

		if (isValidApiKey(apiKey)) {
			setApiKey(apiKey);
			setShowApiKeyModal(false);
		} else {
			setShowApiKeyModal(true);
		};
	}, [user])

	const handleUpdateBounds = (zoom: number) => {
		return (newBounds: L.LatLngBounds) => {
			setBounds(newBounds);
			cacheBoundsAsCookie(bounds, zoom);
		}
	}

	// Update API key and credit balance from database
	useEffect(() => {
			updatePageApiKey()
			.catch(console.error)
	}, [apiKey, updatePageApiKey]);

	// Update the new elevation data
	useEffect(() => {
		const isValidBounds = elevationModel.isValidBounds(bounds);
		if (isValidBounds) {
			const newElevationData: OpenTopographyElevationData = {
				bounds,
				elevationModel,
				projection,
			};
			setElevationData(newElevationData);
		}
		setValid(isValidBounds);
	}, [bounds, elevationModel, projection, setElevationData, setValid])

	return <>
		<div className="page-layout" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<div style={{ display: "flex", flexGrow: 1, width: "100%" }}>
				<MapSelector bounds={bounds} handleBounds={handleUpdateBounds} zoom={initialZoom} />
			</div>
			<div style={{ display: "flex", flexGrow: 0 }}>
				<Footer>
					<Box>
						Columns x Rows
					</Box>
					<div style={{ display: "inline-flex", gap: "2rem" }}>
						<span className="dem-dropdown__width">
							<DEMDropdown
								elevationModel={elevationModel}
								onChange={setElevationModel}
								defaultValue={DEFAULT_ELEVATION_MODEL}
								SelectProps={{
									autoWidth: true,
									MenuProps: {
										slotProps: { paper: { className: "dem-dropdown__width" } },
										anchorOrigin: { horizontal: "right", vertical: "top" },
										transformOrigin: { horizontal: "right", vertical: "bottom" },
									}
								}} />
						</span>
						<span className="projection-dropdown__width">
							<ProjectionDropdown
								projection={projection}
								onChange={setProjection}
								defaultValue={DEFAULT_PROJECTION}
								SelectProps={{
									MenuProps: {
										slotProps: { paper: { className: "footer__projection-dropdown__width" } },
										anchorOrigin: { horizontal: "right", vertical: "top" },
										transformOrigin: { horizontal: "right", vertical: "bottom" },
									}
								}}
							/>
						</span>
					</div>
				</Footer>
			</div>
		</div>
		<ApiKeyModal open={showApiKeyModal} setOpen={setShowApiKeyModal} updateApiKey={updatePageApiKey} />
	</>
}

export default DEMSelection;
